import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject, forkJoin } from 'rxjs';
import { CommonService } from './../services/common.service'
import { environment } from '../../environments/environment';
import { JoinMeeting } from '../../app/samvaad-join-meeting.component';
import { dataDictionary } from '../../app/dataDictionary';

export abstract class BaseComponent {
    table: Array<any> = [];
    isEdit: boolean;
    tenanates: Array<any> = [];
    roleId: any = sessionStorage.getItem('RoleId');
    TenantCode: any = sessionStorage.getItem('TenantCode');
    userId: any = sessionStorage.getItem('UserId');
    myForm: FormGroup;
    editData: any = {};
    tId: string = '';
    file: File;
    fileName: string;
    courses: Array<any> = [];
    courseId: string = '';
    shedules: Array<any> = [];
    schedules: Array<any> = [];
    subjects: Array<any> = []; subjectId: string = ''
    scheduleId: string = '';
    public dtTrigger: Subject<any> = new Subject<any>();
    dtOptions: any = {};
    formBuilder: FormBuilder = new FormBuilder();
    loadTenanat: boolean = true;
    fileUrl: string = environment.fileUrl;
    videoUrl: string = environment.videoUrl;
    MeetingCheckSumUrl = new JoinMeeting();
    is_company: boolean = sessionStorage.is_company == 'false' ? false : true;
    academics: Array<any> = []; schools: Array<any> = [];
    semesters: Array<any> = []; studyyears: Array<any> = [];
    academicId: string = ''; semesterId: string = '';
    studyYearId: string = '';
    cohorts: Array<any> = [];
    cohortId: string = '';
    isRecommondations: boolean = false;
    isUploadPortfolio: boolean = false;
    disableProgramDetailsActive: boolean = false;
    showReferredBox: boolean = false;
    Referrals: boolean = false;
    applicationProgram: boolean = false;
    programDetailsActive: boolean = true;
    personalEmail: string = '';
    allRoles = [
        { id: 1, role: 'Admin' },
        { id: 2, role: 'Trainer' },
        { id: 3, role: 'Trainee' },
        { id: 4, role: 'Super Admin' }
    ];
    Programs: Array<any> = [];
    referrals: Array<any> = [];
    selectedReferral: string = '';
    REFERRAL_NAME: string = '';
    progress: number = 0; progressOne: number = 0; progressTwo: number = 0; progressThree: number = 0;
    isProgress: boolean = false; isProgressOne: boolean = false; isProgressTwo: boolean = false; isProgressThree: boolean = false;
    get RoleName(): string {
        if (+this.roleId == 1 && ["shiksion.com", "localhost"].includes(location.hostname))
            return this.allRoles[1].role;
        else {
            const role: string = this.allRoles.find(e => e.id == +this.roleId)?.role ?? '';
            return role;
        }
    }
    get isPermission(): boolean {
        if (([1, 4].includes(+this.roleId)) || ((+this.roleId == 2) && (this.is_company == false)))
            return true;
        else
            return false;
    }

    get ScreenPermission(): boolean {
        /*
        2--->Trainer
        3--->Trainee
        */
        return ((this.is_company == false) || ([2, 3].includes(+this.roleId)));
    }

    //   fb:FormBuilder=new FormBuilder();
    get isSuperAdmin(): boolean {
        if (sessionStorage.getItem('RoleId') == '4') { return true } else return false;
    }
    constructor(protected CommonService: CommonService, protected toastr: ToastrService) {
        if (this.loadTenanat) this.getTennates();
    }
    activeSpinner() {
        this.CommonService.activateSpinner();
    }

    deactivateSpinner() {
        this.CommonService.deactivateSpinner()
    }
    getGridData(route: string, payload, callBack?: Function) {
        this.activeSpinner();
        this.CommonService.postCall(route, payload).subscribe(
            (res: any) => {
                this.deactivateSpinner()
                this.table = [];
                setTimeout(() => {
                    this.table = res;
                    if (callBack) {
                        callBack();
                    }
                })
            }, err => {
                this.deactivateSpinner()

            }
        )
    }
    public getTennates(callBack?: Function) {
        if (!this.isSuperAdmin) return
        this.activeSpinner();
        this.CommonService.postCall('GetTenantByRoleId', { RoleId: this.roleId }).subscribe(
            (res) => {
                this.tenanates = res;
                this.deactivateSpinner();
                if (callBack) {
                    callBack();
                }
            }, err => {
                this.deactivateSpinner()
            }
        )
    }
    renderDataTable() {
        let t = $('#DataTables_Table_0').DataTable();
        t.destroy();
        this.dtTrigger.next();
    }

    loadDataTable(tableName: string = "DataTables_Table_0") {
        let t = $('#' + tableName).DataTable();
        t.destroy();
        // this.dtTrigger.next();
    }
    changeTname() { }

    ///get admin cources
    getCourses(callback?: Function) {
        this.activeSpinner()
        let id = this.tId == '' ? null : this.tId
        this.CommonService.getAdminCourses(id).subscribe((res: any) => {
            this.courses = res;
            this.deactivateSpinner();
            if (callback) {
                callback();
            }
        }, e => {
            this.deactivateSpinner()
        });

    }
    courseChange(callBack?: Function) {
        let data = {
            "CourseId": this.courseId
        }
        this.activeSpinner()
        this.CommonService.getAdminCourseSchedule(data).subscribe((res: any) => {
            this.deactivateSpinner()
            this.shedules = res;
            this.schedules = res;
            if (callBack) {
                callBack()
            }
        }, e => { this.deactivateSpinner() });
    }

    //////////////////default action in page////////////////////
    post(url, payload, callback?: Function) {
        this.activeSpinner();
        this.CommonService.postCall(url, payload).subscribe(
            (res) => {
                this.deactivateSpinner();
                if (callback) {
                    callback(res);
                }
            }, err => {
                this.deactivateSpinner();
            }
        )
    }


    add() { }
    close() { }
    onSubmit(form?: FormGroup) { }
    edit(data) { }
    //////////////////default action in page////////////////////

    loadReportDtOptions() {
        this.dtOptions = {
            dom: 'Bfrtip',
            buttons: ['excel'],
            order: []
        }
    }
    back() {
        window.history.back();
    }
    studentCourses() {
        this.activeSpinner();
        this.CommonService.getCourses().subscribe((res: any) => {
            this.deactivateSpinner();
            this.courses = res;
        }, e => { this.deactivateSpinner(); })
    }

    fileSizeCalculation(fileSize: number, targetSize: number): boolean {
        const currentfileSize = Math.round((fileSize / (1024 * targetSize)));
        return (currentfileSize >= (1024 * targetSize));
    }

    getFormValidationErrors(formObj: any) {
        Object.keys(formObj.controls).forEach(key => {
            const controlErrors: ValidationErrors = formObj.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    loadImages(courseName: string) {
        const imageList = [
            { name: 'Languages', path: '../../../../assets/new-images/language.jpg' },
            { name: 'Music', path: '../../../../assets/new-images/music.jpg' },
            { name: 'Cooking', path: '../../../../assets/new-images/cooking.jpg' },
            { name: 'Programming Languages', path: '../../../../assets/new-images/ProgrammingLanguages.jpeg' },
            { name: 'Post-Graduate Programme in Business Analytics', path: '../../../../assets/new-images/pg.jpg' },
            { name: 'Angular', path: '../../../../assets/new-images/angular.jpg' },
            { name: 'Machine Learning & AI', path: '../../../../assets/new-images/artificial_intelligence.jpg' },
            { name: 'Web Development', path: '../../../../assets/new-images/web_development.jpg' },
        ];

        let imageObj = imageList.find(e => e.name == courseName);
        return imageObj ? imageObj.path : '../../../../assets/img/course-card/image.png';
    }

    getImagePath(path: string, courseName: string): string {
        const index = path?.indexOf('/HOME') ?? -1;

        const newPath: string = index > -1 ? `${this.fileUrl}${path}` : this.loadImages(courseName);
        return newPath;
    }
    getImagePathCourse(path: string, courseName: string): string {
        const newpath = `${this.videoUrl}${path}`
        return newpath;
    }
    getMeetingDetails(url) {
        const meetingURLParams = url.split('/');
        const meetingId = meetingURLParams[meetingURLParams.length - 1];
        this.activeSpinner();
        this.CommonService.getCall('conclave/nojwt/meeting/getMeetingDetails/', meetingId, true).subscribe((res: any) => {
            const data = res.data.meetingdetails;
            this.getCheckSumUrl(data);
            this.deactivateSpinner();
        }, e => {
            this.deactivateSpinner()
            // console.log(e, 'error getMeetingDetails');
            this.toastr.error(e.error?.message);
        })
    }

    getCheckSumUrl(data) {
        this.activeSpinner();
        let payload = {
            ...this.MeetingCheckSumUrl,
            // fullName: data.fullName,
            fullName: sessionStorage.getItem('Username'),
            meetingID: data.meetingID,
            role: data.role,
            endTime: data.EndTime,
            meetingTitle: data.name,
            hostName: data.hostName,
            startTime: data.StartTime,
            domain_URL: data.domainUrl,
            username: sessionStorage.getItem('Username'),
            mail: sessionStorage.getItem('USERNAME'),
        }
        this.CommonService.postCall('conclave/nojwt/session/getHostCreateAndJoinCheckSumUrl', payload, true).subscribe((res: any) => {
            this.deactivateSpinner();
            this.joinSamvaadMeeting(res);
        }, e => {
            this.deactivateSpinner();
            // console.log(e, '--------getHostCreateAndJoinCheckSumUrl');
            this.toastr.error(e.error?.message);
        })
    }

    joinSamvaadMeeting(res) {
        window.open(res.data.joinUrl)
    }

    setServerPath(path: string) {
        return (this.fileUrl + path);
    }

    convertDataUrlToBlob(dataUrl: any) {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new Blob([u8arr], { type: mime });
    }

    generateFile(ImageURL: string = '', filename: string = 'Sample12345.png', extension: string = 'png') {
        const file = new File([this.convertDataUrlToBlob(ImageURL)], filename, { type: `image/${extension}` });
        return file;
    }
    // ----------------------------Reusable Validations---------------------------------------------
    // --------------only allow Numbers Validation in input type text-----------------------
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }
    getChartOfAccountInfo(): any {
        let { chartOfAccountId = 0 } = sessionStorage;
        if (+chartOfAccountId <= 0) {
            this.activeSpinner();
            this.CommonService.getCall(`ChartOfAccount/GetList/${this.TenantCode}/-1`).subscribe((res: any) => {
                this.deactivateSpinner();
                if (res.status == true && res.data.length > 0) {
                    sessionStorage.chartOfAccountId = res.data[0].cha_chartofaccount_id;
                    return +sessionStorage.chartOfAccountId;
                }
            }, e => {
                this.deactivateSpinner();
                return 0;
            })
        }
        else
            return chartOfAccountId;

    }

    voucherOnKeyPress(event: any) {
        const charCode = event.which ? event.which : event.keyCode;
        if ((charCode >= 48 && charCode <= 57) || charCode === 46) {
            if (charCode === 46 && event.target.value.includes('.')) {
                event.preventDefault();
            } else {
                const dotIndex = event.target.value.indexOf('.');
                if (dotIndex !== -1 && event.target.value.substring(dotIndex + 1).length >= 4) {
                    event.preventDefault();
                }
            }
        } else {
            event.preventDefault();
        }
    }
    programDropdown() {
        this.activeSpinner()
        let payLoad = {
            TENANT_CODE: this.tId || localStorage.getItem('TenantCode'),
            ROLE_NAME: localStorage.getItem('RoleName'),
            USER_ID: localStorage.getItem('UserId')
        }
        this.CommonService.postCall("LoadChapterCourse", payLoad).subscribe((res: any) => {
            this.courses = res;
            this.deactivateSpinner();
        }, e => { this.deactivateSpinner() });
    }
    getAcademic() {
        this.activeSpinner()
        this.CommonService.getCall('Academic/GetAcademicDropDownList/' + localStorage.getItem('TenantCode')).subscribe((res: any) => {
            this.academics = res;
            this.deactivateSpinner()
        }, e => {
            this.deactivateSpinner()
        })
    }
    getSchools() {
        this.activeSpinner();
        let payLoad: any = {
            TENANT_CODE: localStorage.getItem('TenantCode')
        }
        this.schools = [];
        this.CommonService.postCall('CourseCategory/GetCourseCategory', payLoad).subscribe((res: any) => {
            this.schools = res;
            this.deactivateSpinner();
        }, e => { this.deactivateSpinner() })
    }
    getSubjects() {
        this.activeSpinner();
        let payLoad: any = {
            tnt_code: localStorage.getItem('TenantCode')
        }
        this.subjects = [];
        this.CommonService.postCall('Subject/LoadSubjectsList', payLoad).subscribe((res: any) => {
            this.subjects = res;
            this.deactivateSpinner();
        }, e => { this.deactivateSpinner() })
    }
    getSemesterOrCourseYear() {
        this.activeSpinner()
        let courseYear = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear });
        let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
        // let section = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Sections });
        let dropdowns = ['studyyears', 'semesters']
        forkJoin([courseYear, semister]).subscribe((res) => {
            dropdowns.map((key, index) => {
                this[key] = res[index]
            });
            this.deactivateSpinner()
        }, err => { this.deactivateSpinner() });
    }
    changeProgram(programId: any) {
        this.activeSpinner();
        this.cohorts = [];
        this.CommonService.postCall('CohortCurriculumMapping/LoadCohortMaplistByCourse', {
            "COURSE_ID": programId,
            "TNT_CODE": this.TenantCode
        }).subscribe((res: any) => {
            this.cohorts = res;
            this.deactivateSpinner();
        }, e => {
            this.deactivateSpinner();
        })
    }
    getCohortsDrpDown() {
        this.cohorts = [];
        this.CommonService.postCall('CohortManagement/GetCohortDropdownlist', {
            "TNT_CODE": this.TenantCode
        }).subscribe((res: any) => {
            this.cohorts = res;
        })
    }
    getStudyYearDropdown() {
        let courseYear = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.CourseYear });
        let dropdowns = ['studyyears',];
        forkJoin([courseYear]).subscribe((res) => {
            dropdowns.map((key, index) => {
                this[key] = res[index]
            });
        });
    }
    getSemesterDropdown() {

        let semister = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Semester });
        // let section = this.CommonService.postCall('GetDictionaryByKey', { DictionaryCode: dataDictionary.Sections });
        let dropdowns = ['semesters']
        forkJoin([semister]).subscribe((res) => {
            dropdowns.map((key, index) => {
                this[key] = res[index]
            });
        });
    }
    loadPrograms() {
        this.activeSpinner();
        this.CommonService.getAdminCourses().subscribe((res: any) => {
            this.Programs = res;
            // this.onProgramChange(this.editData.COURSE_ID);
            this.deactivateSpinner();
        }, e => {
            this.deactivateSpinner();
        })

    }
    getReferrals() {
        this.referrals = [];
        this.CommonService.getCall('Registration/LoadReferncePlatforms').subscribe((res: any) => {
            this.referrals = res;
        })
    }
    onlyAlphanumerics(event: KeyboardEvent) {
        const regex = new RegExp("^[a-zA-Z0-9]+$");
        const key = String.fromCharCode(event.keyCode || event.which);
    
        // Allow backspace, tab, arrow keys, delete, etc.
        if (event.key === "Backspace" || event.key === "Tab" || event.key === "ArrowLeft" || event.key === "ArrowRight") {
            return;
        }
    
        if (!regex.test(key)) {
            event.preventDefault();
        }
    }

    showportfolio(event: any) {
        if (event == 'true' || event==true) {
          this.isUploadPortfolio = true;
        } else {
          this.isUploadPortfolio = false;
        }
      }
}