<div class="page-wrapper pt-4">
  <div class="page-content pt-0 pe-2 ps-2">
    <div class="container-fluid">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card rounded shadow-lg">
                  <div
                    class="card-header headerbg"
                    style="border-bottom: 3px solid #d8eff8"
                  >
                    <h5 class="mb-0">
                      {{ headingName.UserRegistrationApprovalList }}
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="pt-2 table-responsive" *ngIf="table.length">
                      <table
                        datatable
                        id="DataTables_Table_0"
                        class="table-striped table-bordered mt-2 pt-2 table-sm small"
                        style="width: 100%"
                      >
                        <thead
                          class="top"
                          style="background-color: #624fd7; color: #ffffff"
                        >
                          <tr>
                            <th>Full Name</th>
                            <!-- <th>Last Name</th> -->
                            <!-- <th>Email Id</th> -->
                            <th>Program Name</th>
                            <th>Contact Details</th>
                            <th>Application Form</th>
                            <th>Uploaded Documents</th>
                            <th>Edit/Update Application</th>
                            <th>Verification Status</th>
                            <th>Comments</th>
                            <th>Application Status</th>
                            <th>Admission Status</th>
                            <th>Application Date</th>
                            <th>Logs</th>
                            <th>Action</th>

                            <!-- <th>Mobile Number</th>
                                                          <th>Application Form</th>
                                                          <th>Action</th>
                                                          <th>Uploaded Documents</th> -->
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of table">
                            <td>{{ item.firstname }} {{ item.lastname }}</td>
                            <!-- <td>{{item.lastname}}</td> -->
                            <!-- <td>{{item.name}}</td> -->
                            <td>{{ item.course_name }}</td>
                            <td>
                              {{ item.mobileno_code_name }} {{ item.mobileno }}
                            </td>

                            <td>
                              <button
                                class="btn btn-save btn-sm"
                                data-bs-target="#applicationPopup"
                                data-bs-toggle="modal"
                                (click)="userDetailsMethod(item)"
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-save"
                                data-bs-toggle="modal"
                                data-bs-target="#myModal"
                                (click)="getDocuments(item)"
                              >
                                View
                              </button>
                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-save"
                                (click)="updateUserApplication(item)"
                              >
                                Edit
                              </button>
                            </td>

                            <td>
                              {{ item.verification_status }}
                            </td>
                            <td>
                              <button
                                class="btn btn-sm btn-save"
                                *ngIf="item.verification_status == 'RE-VERIFY'"
                                (click)="showComments(item.sentback_comments)"
                              >
                                View Comments
                              </button>
                            </td>
                            <td>
                              {{ item.application_status }}
                            </td>
                            <td>
                              {{ item.is_approved }}
                            </td>
                            <td>
                              {{ item.application_date }}
                            </td>
                            <td>
                              <button
                                class="btn btn-save btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#userlogs"
                                (click)="getuserLogs(item)"
                              >
                                view Logs
                              </button>
                            </td>
                            <td style="width: 8% !important">
                              <button
                                class="btn btn-save btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target="#reason"
                                (click)="userDataObj(item)"
                                [attr.disabled]="
                                  item?.verification_status == 'VERIFIED' &&
                                  item?.application_status == 'SHORTLISTED' &&
                                  item?.is_approved == 'ADMISSIBLE'
                                    ? true
                                    : null
                                "
                              >
                                <i class="fas fa-edit"></i> Action
                              </button>

                              <!-- <div class="button-group d-none">
                                                                  <button class="btn btn-success btn-sm"
                                                                      (click)="ApproveUser(1,item)">Admissible </button>
                                                                  <button class="btn btn-primary btn-sm"
                                                                      data-bs-toggle="modal" data-bs-target="#reason"
                                                                      (click)="reject(3,item)">Feedback </button>
                                                                  <button class="btn btn-danger btn-sm"
                                                                      data-bs-toggle="modal" data-bs-target="#reason"
                                                                      (click)="reject(2,item)">Not
                                                                      Admissible</button>
                                                              </div> -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="text-center" *ngIf="!table.length">
                      No Records to display
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" id="myModal">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        ></button>
      </div>
      <div class="modal-body">
        <table
          datatable
          id="DataTables_Table_0"
          class="table-striped table-bordered mt-2 pt-2 table-sm small"
          *ngIf="uploadRes.length"
          style="width: 100%"
        >
          <thead class="top" style="background-color: #624fd7; color: #ffffff">
            <tr>
              <th>Academic Supporting Documents</th>
              <!-- <th>High School Transcript</th>
                              <th>National ID/Passport</th> -->
              <!-- <th>Passport Size Photograph</th> -->
              <!-- <th>Portfolio</th> -->
              <!-- <th>Application Essay</th> -->
            </tr>
            <!-- <tr></tr>
                              <tr></tr> -->
          </thead>
          <tbody>
            <tr *ngFor="let item of uploadRes">
              <td>
                <button
                  class="btn btn-save btn-sm"
                  (click)="download(item?.UPLOAD_1)"
                >
                  View
                </button>
              </td>
              <!-- <td><button class="btn btn-info btn-sm"
                                      (click)="download(uploadRes?.upload_2)">Download</button></td>
                              <td><button class="btn btn-info btn-sm"
                                      (click)="download(uploadRes?.upload_3)">Download</button></td> -->
              <!-- <td><button class="btn btn-info btn-sm"
                                      (click)="download(uploadRes?.upload_4)">View</button></td> -->
              <!-- <td>
                                  <b *ngIf="uploadRes?.upload_3 == null">Not Uploaded</b>
                                  <button class="btn btn-info btn-sm" *ngIf="uploadRes?.upload_3 !=null"
                                      (click)="download(uploadRes?.upload_3)">View</button>
                              </td> -->
              <!-- <td><button class="btn btn-info btn-sm"
                                      (click)="download(uploadRes?.upload_5)">Download</button></td> -->
            </tr>
            <!-- <tr>
                                  <td>dd</td>
                              </tr>
                              <tr>
                                  <td>ff</td>
                              </tr> -->
          </tbody>
        </table>
        <div class="text-center" *ngIf="!uploadRes.length">
          No Records to display
        </div>
        <table
          datatable
          id="DataTables_Table_0"
          class="table-striped table-bordered mt-2 pt-2 table-sm small"
          style="width: 100%"
        >
          <thead class="top" style="background-color: #624fd7; color: #ffffff">
            <tr>
              <th>Portfolio</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <b *ngIf="uploadResponse?.upload_3 == null">Not Uploaded</b>
                <button
                  class="btn btn-info btn-sm"
                  *ngIf="uploadResponse?.upload_3 != null"
                  (click)="download(uploadResponse?.upload_3)"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-bs-dismiss="modal"
          id="md_close"
          (click)="close()"
        >
          Close
        </button>
        <!-- <button type="button" class="btn btn-primary">Save</button> -->
      </div>
    </div>
  </div>
</div>

<!-- <button data-bs-target="reason" data-bs-toggle="modal" id="rejectReason"></button> -->
<div class="modal" tabindex="-1" id="reason">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          id="Close"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <form
          [formGroup]="StatusForm"
          (onSubmit)="callApiAndUpdateUI(StatusForm)"
        >
          <div class="row">
            <!-- <div class="col-xl-12"> -->
            <div class="col-xl-6">
              <label class="form-label">Verification Status</label>
              <select
                class="form-control form-control-sm"
                [attr.disabled]="
                  Verification == '5'
                    ? true
                    : userObj?.verification_status == 'VERIFIED'
                    ? true
                    : null
                "
                [(ngModel)]="Verification"
                #vs
                formControlName="VERIFICATION_STATUS"
              >
                <!-- <option value="">Select</option> -->
                <option value="0">Unverified</option>
                <option value="5">Verified</option>
                <option value="3">Follow Up</option>
              </select>
            </div>
            <div class="col-xl-6">
              <label class="form-label">Shortlisting Status</label>
              <select
                class="form-control form-control-sm"
                formControlName="APPLICATION_STATUS"
                [attr.disabled]="
                  vs.value != '5'
                    ? true
                    : ss.value == '1'
                    ? true
                    : userObj?.application_status == 'SHORTLISTED'
                    ? true
                    : null
                "
                [(ngModel)]="shortListing"
                #ss
              >
                <option value="0">Select</option>
                <option value="1">Shortlisted</option>
                <option value="2">Not Shortlisted</option>
              </select>
            </div>
            <!-- <div class="col-xl-6">
                              <label class="form-label">Admission Status</label>
                              <select class="form-control form-control-sm pt-2" [(ngModel)]="admissionStatus"
                                  formControlName="IS_APPROVED"
                                  [attr.disabled]="ss.value !='1'?true:null || admissionStatus==1?true:null || userObj?.is_approved=='ADMISSIBLE'?true:null">
                              
                                  <option value="0">Select</option>
                                  <option value="1">Admissible</option>
                                  <option value="2">Not Admissible</option>
                              </select>
                          </div> -->
          </div>
          <div class="mb-3" *ngIf="Verification == 3">
            <label for="exampleFormControlTextarea1" class="form-label"
              >Comments</label
            ><span class="text-danger">*</span>
            <textarea
              class="form-control"
              [(ngModel)]="comments"
              id="exampleFormControlTextarea1"
              rows="5"
              formControlName="COMMENTS"
            ></textarea>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          data-bs-dismiss="modal"
          (click)="close()"
          id="Close"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-save"
          (click)="callApiAndUpdateUI(StatusForm)"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>

<!-- --------------------------------APPLICATION FORM POPUP------------------------------------ -->
<div
  class="modal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  id="applicationPopup"
>
  <div class="modal-dialog modal-fullscreen">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Application Details</h5>
        <button
          type="button"
          *ngIf="isBtnShow"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="ClosePopup"
        ></button>
        <button
          *ngIf="isBtnShowforEdit"
          (click)="closeApplicationPopup()"
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          id="ClosePopup"
        ></button>
      </div>
      <div class="modal-body">
        <div id="printtable" class="pt-5">
          <table
            width="980"
            cellspacing="0"
            cellpadding="0"
            style="margin: auto"
          >
            <tbody>
              <tr>
                <td>
                  <table
                    width="980"
                    border="0"
                    cellspacing="10"
                    cellpadding="0"
                  >
                    <tbody>
                      <tr>
                        <td
                          height=""
                          style="text-align: center"
                          valign="middle"
                        >
                          <img
                            src="../../../../assets/img/ouk-logo-new.png"
                            width="200"
                            alt=""
                          />
                        </td>
                        <td rowspan="4">
                          <div
                            class="pt-2 d-flex"
                            style="width: 150px; height: 150px"
                          >
                            <img
                              [src]="userDetails?.result?.upload_4"
                              class="img-fluid m-auto"
                              style="margin: auto; max-height: 100%"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center">&nbsp;</td>
                      </tr>
                      <tr>
                        <td style="text-align: center">
                          <table width="100%">
                            <tr>
                              <td width="35%">
                                <p
                                  style="
                                    margin: 0px;
                                    font-size: 12px !important;
                                  "
                                >
                                  <b>P.O. BOX</b> 2440-00606 NAIROBI, KENYA
                                </p>
                              </td>
                              <td width="35%">
                                <p
                                  style="
                                    margin: 0px;
                                    font-size: 12px !important;
                                  "
                                >
                                  <b>TELEPHONE:</b> 0202000211/0202000212
                                </p>
                              </td>
                              <td width="30%">
                                <p
                                  style="
                                    margin: 0px;
                                    font-size: 12px !important;
                                  "
                                >
                                  <b>EMAIL:</b> admissions&#64;ouk.ac.ke
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td style="text-align: center">&nbsp;</td>
                      </tr>
                      <tr>
                        <td style="text-align: center">
                          <h4
                            style="
                              margin-top: 0;
                              margin-bottom: 10px;
                              font-weight: 600;
                            "
                          >
                            OUK Admissions Office
                          </h4>
                          <h5 style="color: #fe7f50; font-weight: 500">
                            STUDENT APPLICATION ONLINE FORM
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>

              <tr>
                <td colspan="2" style="padding: 10px">
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                    style="font-size: 13px"
                  >
                    <tbody>
                      <tr>
                        <td
                          colspan="6"
                          style="
                            border-bottom: 1px dashed #333;
                            font-size: 13px;
                          "
                        >
                          <h5><b>(A) PROGRAM SELECTION</b></h5>
                        </td>
                      </tr>
                      <tr>
                        <td><b>Application Type</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>
                          {{ userDetails?.get_cluster?.APPLICATION_TYPE_NAME }}
                        </td>
                        <td><b>Programme</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>{{ userDetails?.result?.course_name }}</td>
                      </tr>

                      <tr *ngIf="userDetails?.result?.is_cluster">
                        <td colspan="6">
                          <table
                            class="table-bdr"
                            width="100%"
                            border="1"
                            cellspacing="0"
                            cellpadding="6"
                          >
                            <tbody>
                              <tr>
                                <th>Programme Cluster</th>
                                <th>Subject Name(eg MAT)</th>
                                <th>Grade obtained</th>
                              </tr>
                              <tr>
                                <td>CLUSTER SUBJECT 1</td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT1_NAME
                                  }}
                                </td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT1_GRADE
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>CLUSTER SUBJECT 2</td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT2_NAME
                                  }}
                                </td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT2_GRADE
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>CLUSTER SUBJECT 3</td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT3_NAME
                                  }}
                                </td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT3_GRADE
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>CLUSTER SUBJECT 4</td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT4_NAME
                                  }}
                                </td>
                                <td>
                                  {{
                                    userDetails?.get_cluster
                                      ?.CLUSTER_SUBJECT4_GRADE
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td><b>English</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>
                          {{
                            userDetails?.get_cluster?.ENGLISH_PROFICIENCY_NAME
                          }}
                        </td>
                        <td><b>Computing Skills</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>
                          {{
                            userDetails?.get_cluster
                              ?.COMPUTING_SKILLS_PROFICIENCY_NAME
                          }}
                        </td>
                      </tr>

                      <!-- <tr>
                                     
                                          <td><b>Are you applying for Recognition of Prior Learning (RPL)?</b>
                                          </td>
                                          <td width="2%" style="text-align: center;">:</td>
                                          <td>{{userDetails?.get_cluster?.IS_RPL == true ? 'Yes' : 'No'}}</td>
                                      </tr> -->
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                    style="border-top: 2px dashed #333; font-size: 13px"
                  >
                    <tbody>
                      <tr>
                        <td
                          colspan="6"
                          style="
                            border-bottom: 1px dashed #333;
                            font-size: 13px;
                          "
                        >
                          <h5><b>(B) PERSONAL DETAILS</b></h5>
                        </td>
                      </tr>
                      <tr>
                        <td width="24%"><b>First Name</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">
                          {{ userDetails?.result?.firstname }}
                        </td>
                        <td width="24%"><b>Middle Name</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">
                          {{ userDetails?.result?.middlename }}
                        </td>
                      </tr>
                      <tr>
                        <td width="24%"><b>Last Name</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">{{ userDetails?.result?.lastname }}</td>
                        <td><b>Country of Residence</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>{{ userDetails?.result?.country_of_residence }}</td>
                      </tr>

                      <tr>
                        <td *ngIf="userDetails?.result?.counties_id != 0">
                          <b>County</b>
                        </td>
                        <td
                          *ngIf="userDetails?.result?.counties_id != 0"
                          width="2%"
                          style="text-align: center"
                        >
                          :
                        </td>
                        <td *ngIf="userDetails?.result?.counties_id != 0">
                          {{ userDetails?.result?.counties_name }}
                        </td>

                        <td><b>Mobile Number 1</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>
                          {{ userDetails?.result?.mobileno_code_name }}
                          {{ userDetails?.result?.mobileno }}
                        </td>
                        <!-- <td *ngIf="userDetails?.result?.counties_id !=0"><b>Sub County</b></td>
                                                  <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                      style="text-align: center;">:</td>
                                                  <td *ngIf="userDetails?.result?.counties_id !=0">
                                                      {{userDetails?.result?.sub_counties_name}}</td> -->

                        <!-- <td><b>Postal Address</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.postal_address}} </td> -->
                      </tr>
                      <tr>
                        <!-- <td *ngIf="userDetails?.result?.counties_id !=0"><b>Constituency</b>
                                                  </td>
                                                  <td *ngIf="userDetails?.result?.counties_id !=0" width="2%"
                                                      style="text-align: center;">:</td>
                                                  <td *ngIf="userDetails?.result?.counties_id !=0">
                                                      {{userDetails?.result?.con_counties_name}}</td> -->
                      </tr>
                      <!-- <tr>
                                                  <td><b>Physical Address</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.physical_address}}</td>
                                                  <td><b>Postal Code</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.postal_code}}</td>
                                              </tr> -->

                      <tr>
                        <!-- <td><b>Town/City</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.nearest_town}}</td> -->

                        <td width="24%"><b>Current Email</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">{{ userDetails?.result?.username }}</td>

                        <td width="24%"><b>Gender</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">
                          {{ userDetails?.result?.gender_name }}
                        </td>
                      </tr>
                      <tr>
                        <td width="24%"><b>Date of Birth</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">{{ userDetails?.result?.dob }}</td>
                        <td width="24%"><b>Disability</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">
                          {{ userDetails?.result?.isdisability_name }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="24%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES'
                          "
                        >
                          <b>Type Of Disability</b>
                        </td>
                        <td
                          width="2%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES'
                          "
                          style="text-align: center"
                        >
                          :
                        </td>
                        <td
                          width="24%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES'
                          "
                        >
                          {{ userDetails?.result?.typeofdisability_name }}
                        </td>
                        <td
                          width="24%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES' &&
                            userDetails?.result?.typeofdisability_name ==
                              'OTHER'
                          "
                        >
                          <b>Disability Description</b>
                        </td>
                        <td
                          width="2%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES' &&
                            userDetails?.result?.typeofdisability_name ==
                              'OTHER'
                          "
                          style="text-align: center"
                        >
                          :
                        </td>
                        <td
                          width="24%"
                          *ngIf="
                            userDetails?.result?.isdisability_name == 'YES' &&
                            userDetails?.result?.typeofdisability_name ==
                              'OTHER'
                          "
                        >
                          {{ userDetails?.result?.disability_description }}
                        </td>
                      </tr>
                      <!-- <tr>
                                                
                                                  <td><b>Mobile Number 2</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.mobileno2_code_name}}
                                                      {{userDetails?.result?.mobileno2}}</td>
  
                                              </tr> -->

                      <!-- <tr>
                                                  <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                      <h5><b>(C) BIOGRAPHICAL INFORMATION</b></h5>
                                                  </td>
                                              </tr> -->

                      <!-- <tr>
                                                  <td width="24%"><b>Marital Status</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td width="24%">{{userDetails?.result?.maritalstatus_name}}</td>
                                                  <td width="24%"><b>Birth Country</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td width="24%">{{userDetails?.result?.birth_country}}</td>
                                              </tr> -->
                      <!-- <tr>
                                                  <td width="24%"><b>Orphan</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td width="24%">{{userDetails?.result?.orphan
                                                      ==1?'YES':userDetails?.result?.orphan ==2?'NO':'Do Not Wish To
                                                      Disclose'}}</td>
                                              </tr> -->

                      <tr>
                        <!-- <td><b>Nationality</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.nan_code}}</td> -->
                      </tr>
                      <!-- <tr>
  
                                                  <td><b>Identification Document Type</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.id_passport_type_name}}</td>
                                                  <td><b>{{userDetails?.result?.id_passport_type_name}}</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td colspan="4">{{userDetails?.result?.id_passport}}</td>
                                              </tr> -->
                    </tbody>
                  </table>
                  <!-- <table width="100%" border="0" cellspacing="0" cellpadding="6"
                                          style="font-size:13px;">
                                          <tbody>
                                              <tr>
                                                  <td colspan="6" style="border-bottom:1px dashed #333; font-size:13px;">
                                                      <h5><b>(C) Next of Kin/Emergency Contact :</b></h5>
                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td><b>Full Name</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.name_of_emergency}}</td>
                                                  <td><b>Relationship</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.relationshipname_of_emergency_name}}</td>
                                              </tr>
                                              <tr>
                                                  <td><b>Telephone</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.emergency_mobileno_code_name}}
                                                      {{userDetails?.result?.mobile_no_emergency}}</td>
                                                  <td><b>Email Address</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.emailid_of_emergency}}</td>
                                              </tr>
                                              <tr>
                                                  <td><b>Postal Address</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.address_of_emergency}}</td>
                                                  <td><b>Postal Code</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.postalcode_of_emergency}}</td>
  
                                              </tr>
                                              <tr>
                                                  <td><b>City/Town</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.
                                                      nearest_town}}</td>
                                                  <td><b>Country</b></td>
                                                  <td width="2%" style="text-align: center;">:</td>
                                                  <td>{{userDetails?.result?.emergency_country}}</td>
  
                                              </tr>
                                              <tr>
  
                                                  <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                      <b>County</b>
                                                  </td>
                                                  <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0" width="2%"
                                                      style="text-align: center;">:</td>
                                                  <td *ngIf="userDetails?.result?.counties_id_of_emergency !=0">
                                                      {{userDetails?.result?.counties_name_of_emergency}}</td>
  
                                              </tr>
                                          </tbody>
                                      </table> -->
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                    style="border-bottom: 1px dashed #333; font-size: 13px"
                  >
                    <tbody>
                      <tr></tr>
                      <tr
                        style="border-bottom: 0px dashed #333; font-size: 13px"
                      >
                        <td colspan="6">
                          <h5><b>(C) PREVIOUS EDUCATION DETAILS :</b></h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                    style="font-size: 13px"
                  >
                    <tbody>
                      <tr
                        *ngIf="userDetails?.get_cluster?.APPLICATION_TYPE == 1"
                      >
                        <td>
                          <b
                            >Are you applying for Recognition of Prior Learning
                            (RPL)?</b
                          >
                        </td>
                        <td width="2%" style="text-align: center">:</td>
                        <td>
                          {{
                            userDetails?.lst[0]?.IS_RPL == true ? "Yes" : "No"
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngFor="let item of userDetails?.lst">
                    <table
                      width="100%"
                      border="1"
                      cellspacing="0"
                      cellpadding="6"
                    >
                      <tbody>
                        <tr>
                          <td width="24%"><b>Institution Name</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.INSTITUTION_NAME }}</td>
                          <td width="24%"><b>Country</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.EDUCATION_COUNTRY }}</td>
                        </tr>
                        <tr>
                          <td width="24%"><b>Level of Study</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%" colspan="4">
                            {{ item.LEVEL_OF_STUDY }}
                          </td>
                        </tr>
                        <tr>
                          <td width="24%"><b>Start Year</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">
                            {{ item.FROM_PERIOD | date : "yyyy" }}
                          </td>
                          <td width="24%"><b>End Year</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">
                            {{ item.TO_PERIOD | date : "yyyy" }}
                          </td>
                        </tr>
                        <tr>
                          <td width="24%">
                            <b>Examination Number e.g. KCSE index number</b>
                          </td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.EXAM_REG_NO }}</td>
                          <td width="24%"><b>Qualifications Attained</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.QUALIFICATION }}</td>
                        </tr>
                        <tr>
                          <td width="24%"><b>Mean Grade</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.GRADE }}</td>
                        </tr>
                        <!-- <tr>
                                              <td><b>Address</b></td>
                                              <td width="2%" style="text-align: center;">:</td>
                                              <td > {{item.ADDRESS}}</td>
                                              <td><b>Additional Information</b></td>
                                              <td width="2%" style="text-align: center;">:</td>
                                              <td>{{item.ADDITIONAL_INFORMATION}}</td>
                                          </tr> -->
                      </tbody>
                    </table>
                  </div>

                  <!-- <table width="100%" border="0" cellspacing="0" cellpadding="6">
                                          <tbody>
                                              <tr style="border-bottom:1px dashed #333; font-size:13px;">
                                                  <td colspan="6">
                                                      <h5><b>(E) Working Experience</b></h5>
                                                  </td>
                                              </tr>
                                          </tbody>
                                      </table>
                                      <div *ngFor="let item of userDetails?.lstt">
                                          <table width="100%" border="1" cellspacing="0" cellpadding="6">
                                              <tbody>
  
                                                  <tr>
                                                      <td width="24%"><b>Company/Employer</b></td>
                                                      <td width="2%" style="text-align: center;">:</td>
                                                      <td width="24%">{{item.WORKEXP_DETAIL_EMPLOYER}}</td>
                                                      <td width="24%"><b>Designation</b></td>
                                                      <td width="2%" style="text-align: center;">:</td>
                                                      <td width="24%">{{item.WORKEXP_DETAIL_DESIGNATION}}</td>
  
                                                  </tr>
                                                  <tr>
                                                      <td width="24%"><b>Start Date</b></td>
                                                      <td width="2%" style="text-align: center;">:</td>
                                                      <td width="24%">{{item.WORKEXP_DETAIL_FROM_YEAR |
                                                          date:'yyyy-MM-dd'}}</td>
                                                      <td width="24%"><b>End Date</b></td>
                                                      <td width="2%" style="text-align: center;">:</td>
                                                      <td width="24%">{{item.WORKEXP_DETAIL_TO_YEAR | date:'yyyy-MM-dd'}}
                                                      </td>
  
                                                  </tr>
  
                                              </tbody>
                                          </table>
                                      </div> -->

                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                    *ngIf="isRecommondations"
                  >
                    <tbody>
                      <tr
                        style="border-bottom: 1px dashed #333; font-size: 13px"
                      >
                        <td colspan="6">
                          <h5><b>(D) RECOMMENDATIONS :</b></h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div *ngFor="let item of userDetails?.lst_recommandations">
                    <table
                      width="100%"
                      border="1"
                      cellspacing="0"
                      cellpadding="6"
                      *ngIf="isRecommondations"
                    >
                      <tbody>
                        <tr>
                          <td width="24%"><b>Full Name</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.NAME }}</td>
                          <td width="24%"><b>Organization</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.ORGANIZATION }}</td>
                        </tr>
                        <tr>
                          <td width="24%"><b>Country</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.COUNTRY_NAME }}</td>
                          <td width="24%"><b>Mobile Number</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">
                            {{ item.COUNTRY_CONNECTING_CODE }}
                            {{ item.MOBILE_NUMBER }}
                          </td>

                          <!-- <td width="24%"><b>Nationality</b></td>
                                          <td width="2%" style="text-align: center;">:</td>
                                          <td width="24%"> {{userDetails?.result?.sponsors_email}}</td> -->
                        </tr>
                        <tr>
                          <td width="24%"><b>City/Town</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">{{ item.CITY_TOWN }}</td>
                          <td width="24%"><b>Nationality</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">
                            {{ item.RECOMMENDATION_NATIONALITY }}
                          </td>
                        </tr>
                        <tr>
                          <td width="24%"><b>Recommender email</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%" colspan="4">
                            {{ item.EMAIL_ADDRESS }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <table
                    width="100%"
                    border="0"
                    cellspacing="0"
                    cellpadding="6"
                  >
                    <tbody>
                      <tr
                        style="border-bottom: 1px dashed #333; font-size: 13px"
                      >
                        <td colspan="6">
                          <h5>
                            <b
                              >({{ isRecommondations == false ? "D" : "E" }})
                              REFERRAL :</b
                            >
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td width="24%"><b>Referred by</b></td>
                        <td width="2%" style="text-align: center">:</td>
                        <td width="24%">{{ userDetails?.result?.ref_name }}</td>
                        <span
                          *ngIf="userDetails?.result?.referral_platform_id == 8"
                        >
                          <td width="24%"><b>Description</b></td>
                          <td width="2%" style="text-align: center">:</td>
                          <td width="24%">
                            {{ userDetails?.result?.referral_name }}
                          </td>
                        </span>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="footer float-end pt-2">
            <button
              id="Closemodal"
              *ngIf="isBtnShow"
              class="btn btn-sm btn-danger"
              data-bs-dismiss="modal"
            >
              Close</button
            >&nbsp;&nbsp;

            <button
              id="Closemodal"
              *ngIf="isBtnShowforEdit"
              class="btn btn-sm btn-danger"
              data-bs-dismiss="modal"
              (click)="closeApplicationPopup()"
            >
              Close</button
            >&nbsp;&nbsp;
            <button class="btn btn-save btn-sm" (click)="downloadForm()">
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- --------------------------------APPLICATION FORM POPUP END------------------------------------ -->

<div class="modal" tabindex="-1" id="editPopup">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="stepper">
          <div
            *ngFor="let step of steps; index as i"
            class="step"
            [class.active]="i === currentStep"
            [class.completed]="i < currentStep"
          >
            <div
              class="step-circle"
              (click)="goToStep(i)"
              [class.disabled]="i > currentStep"
            >
              {{ i + 1 }}
            </div>
            <div class="step-label">{{ step.label }}</div>
            <div class="step-line" *ngIf="i < steps.length - 1"></div>
          </div>
        </div>

        <!-- Step Content -->
        <div class="step-content">
          <ng-container *ngIf="currentStep === 0">
            <!-- Program Information Form -->
            <form>
              <form
                class="form-container cst_1 cstm_stepper"
                [formGroup]="signUpForm"
              >
                <h6 class="mb-2 mt-3 title_stepper">
                  What would you like to study?
                </h6>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="firstName" class="form-label"
                      >Application Type
                      <span class="text-danger">*</span></label
                    >
                    <select
                      [(ngModel)]="apptype"
                      class="form-select"
                      (change)="applicationType($event)"
                      formControlName="APPLICATION_TYPE"
                      disabled
                      aria-label="Default select example"
                    >
                      <option value="0">Select</option>
                      <option value="1">Undergraduate</option>
                      <option value="2">Postgraduate</option>
                    </select>

                    <span class="text-blue"
                      ><small>(e.g. Undergraduate or Postgraduate)</small></span
                    >
                  </div>
                  <div class="col-md-4">
                    <label for="firstName" class="form-label"
                      >Programme <span class="text-danger">*</span></label
                    >
                    <select
                      [(ngModel)]="courseId"
                      disabled
                      (change)="loadClusterDetails($any($event.target).value)"
                      [ngModelOptions]="{ standalone: true }"
                      class="form-select"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let item of courseList"
                        [value]="item.COURSE_ID"
                      >
                        {{ item.COURSE_NAME }}
                      </option>
                    </select>
                  </div>
                </div>
                <div *ngIf="clusters">
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-bordered table-bdr">
                        <tr>
                          <th>Programme Cluster</th>
                          <th>Subject Name(eg MAT)</th>
                          <th>Grade obtained</th>
                        </tr>
                        <tr>
                          <td>CLUSTER SUBJECT 1</td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT1_NAME"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of clusterDropdowns[0]"
                                [value]="item.CLUSTERDTL_ID"
                              >
                                {{ item.CLUSTER_SUBJECT_NAME }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT1_GRADE"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of ClusterGradeList"
                                [value]="item.CLUSTER_GRADE_ID"
                              >
                                {{ item.CLUSTER_GRADE_NAME }}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>CLUSTER SUBJECT 2</td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT2_NAME"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of clusterDropdowns[1]"
                                [value]="item.CLUSTERDTL_ID"
                              >
                                {{ item.CLUSTER_SUBJECT_NAME }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT2_GRADE"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of ClusterGradeList"
                                [value]="item.CLUSTER_GRADE_ID"
                              >
                                {{ item.CLUSTER_GRADE_NAME }}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>CLUSTER SUBJECT 3</td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT3_NAME"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of clusterDropdowns[2]"
                                [value]="item.CLUSTERDTL_ID"
                              >
                                {{ item.CLUSTER_SUBJECT_NAME }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT3_GRADE"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of ClusterGradeList"
                                [value]="item.CLUSTER_GRADE_ID"
                              >
                                {{ item.CLUSTER_GRADE_NAME }}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>CLUSTER SUBJECT 4</td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT4_NAME"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of clusterDropdowns[3]"
                                [value]="item.CLUSTERDTL_ID"
                              >
                                {{ item.CLUSTER_SUBJECT_NAME }}
                              </option>
                            </select>
                          </td>
                          <td>
                            <select
                              class="form-select form-control-sm"
                              formControlName="CLUSTER_SUBJECT4_GRADE"
                            >
                              <option value="">Select</option>
                              <option
                                *ngFor="let item of ClusterGradeList"
                                [value]="item.CLUSTER_GRADE_ID"
                              >
                                {{ item.CLUSTER_GRADE_NAME }}
                              </option>
                            </select>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <h6 class="mb-2 mt-3 title_stepper">Proficiency</h6>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="firstName" class="form-label"
                      >English <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      formControlName="ENGLISH_PROFICIENCY"
                    >
                      <option value="0">Select Level</option>
                      <option value="1">Beginner</option>
                      <option value="2">Intermediate</option>
                      <option value="3">Advanced</option>
                      <option value="4">Mastery</option>
                    </select>

                    <span class="text-blue small"
                      >(your language proficiency level in both speaking and
                      writing)</span
                    >
                  </div>
                  <div class="col-md-4">
                    <label for="firstName" class="form-label"
                      >Computing Skills
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      formControlName="COMPUTING_SKILLS_PROFICIENCY"
                    >
                      <option value="0">Select Level</option>
                      <option value="1">Beginner</option>
                      <option value="2">Intermediate</option>
                      <option value="3">Advanced</option>
                      <option value="4">Mastery</option>
                    </select>
                    <span class="text-blue small"
                      >(describe your computer handling skills)</span
                    >
                  </div>
                </div>
              </form>
              <div class="text-end">
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="saveProgramData(signUpForm)"
                >
                  Next
                </button>
              </div>
            </form>
          </ng-container>
          <!-- ----Personal details----- -->
          <ng-container *ngIf="currentStep === 1">
            <!-- Personal Information Form -->
            <form>
              <form
                class="form-container cst_1 cstm_stepper"
                [formGroup]="signUpForm"
              >
                <h6 class="mb-2 mt-3 title_stepper">Name</h6>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="firstName" class="form-label"
                      >First Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="firstName"
                      placeholder="First Name"
                      formControlName="FIRSTNAME"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="middleName" class="form-label"
                      >Middle Name</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="middleName"
                      placeholder="Middle Name"
                      formControlName="MIDDLENAME"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                  </div>
                  <div class="col-md-4">
                    <label for="lastName" class="form-label"
                      >Last Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="lastName"
                      placeholder="Last Name"
                      formControlName="LASTNAME"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                  </div>
                </div>

                <h6 class="mb-2 mt-3 title_stepper">
                  Biographical Information
                </h6>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="gender" class="form-label"
                      >Gender <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      id="gender"
                      formControlName="GENDER"
                    >
                      <option value="0">Select Gender</option>
                      <option
                        *ngFor="let item of gender"
                        [value]="item.DICTIONARYID"
                      >
                        {{ item.DICTIONARYNAME }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4">
                    <label for="dob" class="form-label"
                      >Date of Birth <span class="text-danger">*</span></label
                    >
                    <div class="d-flex gap-2">
                      <select
                        class="form-select"
                        id="dobDay"
                        [(ngModel)]="selectedDay"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option *ngFor="let day of days" [value]="day">
                          {{ day }}
                        </option>
                        <!-- Days 1-31 options -->
                      </select>
                      <select
                        class="form-select"
                        id="dobMonth"
                        [(ngModel)]="selectedMonth"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option *ngFor="let month of months" [value]="month">
                          {{ month }}
                        </option>
                      </select>
                      <select
                        class="form-select"
                        id="dobYear"
                        (change)="validateAge(); calculateAge()"
                        [(ngModel)]="selectedYear"
                        [ngModelOptions]="{ standalone: true }"
                      >
                        <option *ngFor="let year of years" [value]="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="form-label"
                      >Disability <span class="text-danger">*</span></label
                    >
                    <div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          (change)="radioChanged(false)"
                          name="no"
                          id="isDisabilityNo"
                          [checked]="isDisabilityStatus === false"
                        />
                        <label class="form-check-label" for="disabilityNo"
                          >No</label
                        >
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          (change)="radioChanged(true)"
                          name="yes"
                          id="isDisabilityYes"
                          [checked]="isDisabilityStatus === true"
                        />
                        <label class="form-check-label" for="disabilityYes"
                          >Yes</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 pt-2" *ngIf="showDropdown">
                    <label for="firstName" class="form-label"
                      >Type of disability
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      (change)="dropdownChanged($event.target.value)"
                      formControlName="type_of_disability"
                    >
                      <option value="">Select Disablity</option>
                      <option value="1">Visual</option>
                      <option value="2">Physical</option>
                      <option value="3">Impaired hearing</option>
                      <option value="4">Autism</option>
                      <option value="5">Other</option>
                    </select>
                  </div>
                  <div class="col-md-4 pt-2" *ngIf="showReasonTextbox">
                    <label for="firstName" class="form-label"
                      >Reason for other
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      formControlName="disability_description"
                      placeholder="Please provide a reason"
                    />
                  </div>
                </div>

                <h6 class="mb-2 mt-3 title_stepper">Contact Information</h6>

                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="mobileNumber" class="form-label"
                      >Mobile Number <span class="text-danger">*</span></label
                    >
                    <div class="d-flex gap-2">
                      <select
                        class="form-select small-width"
                        id="712455789"
                        formControlName="MOBILENO_CODE_ID"
                        [(ngModel)]="countryCode"
                      >
                        <option
                          *ngFor="let item of country"
                          [value]="item.COUNTRY_ID"
                        >
                          {{ item.COUNTRY_CONNECTING_CODE }}
                        </option>
                      </select>
                      <input
                        class="form-control large-width_1"
                        type="text"
                        id="712455789"
                        placeholder="712455789"
                        formControlName="MOBILENO"
                        (blur)="mobileValidation($event, 1)"
                        (keypress)="onKeyPress($event, 1)"
                        (paste)="onPaste($event, 1)"
                        maxlength="12"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label for="email" class="form-label"
                      >Email <span class="text-danger">*</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      disabled
                      [value]="userDetails?.result?.username"
                    />
                  </div>
                </div>
                <h6 class="mb-2 mt-3 title_stepper">Address</h6>
                <div class="row mb-3">
                  <div class="col-md-4">
                    <label for="country" class="form-label"
                      >Country of Residence
                      <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      id="country"
                      (change)="
                        changeCountry(
                          $any($event.target)?.value,
                          'personalDetailsCountry'
                        )
                      "
                      formControlName="COUNTRY_ID"
                    >
                      <option value="0">Select</option>
                      <option
                        *ngFor="let item of country"
                        [value]="item.COUNTRY_ID"
                      >
                        {{ item.COUNTRY_NAME }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4" *ngIf="countyDrpdown">
                    <label for="country" class="form-label"
                      >County <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      (change)="subCounties($any($event.target).value)"
                      formControlName="COUNTIES_ID"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let item of County"
                        [value]="item.COUNTIES_CODE"
                      >
                        {{ item.COUNTIES_NAME }}
                      </option>
                    </select>
                  </div>
                </div>
              </form>
              <div class="text-end">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="previousStep()"
                >
                  Previous
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="savePersonalDetails(signUpForm)"
                >
                  Next
                </button>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="currentStep === 2">
            <!-- Academic Background -->
            <form>
              <form
                class="form-container cst_1 cstm_stepper"
                [formGroup]="signUpForm"
              >
                <span>
                  <h6 class="mb-2 mt-3 title_stepper">
                    Recognition of Prior Learning
                  </h6>

                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label class="form-label"
                        >Are you applying for Recognition of Prior Learning
                        (RPL)? <span class="text-danger">*</span><br />
                        <span class="tooltip-icon">
                          <i class="fas fa-info-circle"></i>
                          <span class="tooltip-text" style="font-weight: 100"
                            >Did you miss out on university placement due to
                            your past grades and now wish to be considered for
                            admission based on your previous training and
                            experience?</span
                          >
                        </span></label
                      >
                      <div>
                        <select
                          (change)="showportfolio($event.target.value)"
                          class="form-select"
                          formControlName="IS_RPL"
                        >
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        <!-- <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="disability"
                              id="RPLNo"
                              value="no"
                            />
                            <label class="form-check-label" for="RPLNo"
                              >No</label
                            >
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="disability"
                              id="RPLYes"
                              value="yes"
                            />
                            <label class="form-check-label" for="RPLYes"
                              >Yes</label
                            >
                          </div> -->
                      </div>
                    </div>
                    <div class="col-md-6 mb-3" *ngIf="isUploadPortfolio">
                      <label for="formFile" class="form-label"
                        >Upload your Portfolio Documents for Recognition of
                        Prior Learning (RPL)</label
                      >
                      <div class="input-group">
                        <input
                          type="file"
                          formControlName="UPLOAD_3"
                          (change)="changeFile($event, 3)"
                          class="form-control"
                          placeholder="Recipient's username"
                          aria-label="Recipient's username"
                          aria-describedby="basic-addon2"
                          #fileInputRef
                        />
                        <span
                          class="input-group-text"
                          style="
                            cursor: pointer;
                            background-color: #ff7f50;
                            color: white;
                          "
                          id="basic-addon2"
                          (click)="removeFile('2')"
                          >Remove</span
                        >
                      </div>
                      <div class="form-text">
                        (Upload your certificates a short courses
                        certificates/diploma/degree in single PDF file.)
                      </div>
                      <p *ngIf="upload_3 != ''">
                        {{ upload_3 }}
                      </p>
                      <div *ngIf="isProgressThree" class="progress my-3">
                        <div
                          class="progress-bar progress-bar-info progress-bar-striped"
                          role="progressbar"
                          attr.aria-valuenow="{{ progressThree }}"
                          aria-valuemin="0"
                          aria-valuemax="100"
                          [ngStyle]="{ width: progressThree + '%' }"
                        >
                          {{ progressThree }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </span>
                <h6 class="mb-2 mt-3 title_stepper">Academic Background</h6>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="Institution_Name" class="form-label"
                      >Institution Name
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Institution_Name"
                      placeholder="Institution Name"
                      [(ngModel)]="INSTITUTION_NAME"
                      [ngModelOptions]="{ standalone: true }"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Level_of_Study" class="form-label"
                      >Level of Study <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Level_of_Study"
                      placeholder="Level of Study"
                      [(ngModel)]="LEVEL_OF_STUDY"
                      [ngModelOptions]="{ standalone: true }"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                    <div class="form-text">
                      e.g. Primary School, High School, Certificate, Diploma,
                      Degree, Masters
                    </div>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="country" class="form-label">Country </label>
                    <select
                      class="form-select"
                      id="country"
                      [(ngModel)]="EDUCATION_COUNTRY_ID"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let item of country"
                        [value]="item.COUNTRY_ID"
                      >
                        {{ item.COUNTRY_NAME }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Qualifications_Attained" class="form-label"
                      >Qualifications Attained
                      <span class="text-danger">*</span></label
                    >
                    <select
                      (change)="
                        yearValidate($any($event.target).value);
                        clearOther($any($event.target).value)
                      "
                      class="form-select"
                      [(ngModel)]="QUALIFICATION_ID"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="">Select</option>
                      <option value="1">KCSE</option>
                      <option value="2">IGCSE</option>
                      <option value="3">GCSE</option>
                      <option value="4">IB</option>
                      <option value="5">Others</option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3" *ngIf="QUALIFICATION_ID == '5'">
                    <label class="form-label">Reason for Others </label>
                    <input
                      class="form-control"
                      [(ngModel)]="QUALIFICATION"
                      [ngModelOptions]="{ standalone: true }"
                      type="text"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Start_Year" class="form-label"
                      >Start Year <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      id="Start_Year"
                      [(ngModel)]="selectedYearStart"
                      (change)="
                        generateDropdowns(); clearMonthDayWrk('start', 'edu')
                      "
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option *ngFor="let year of years" [value]="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="End_Year" class="form-label"
                      >End Year <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      id="End_Year"
                      [(ngModel)]="selectedYearEnd"
                      (change)="generateDropdowns(); validateDateRange('edu')"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option *ngFor="let year of years" [value]="year">
                        {{ year }}
                      </option>
                    </select>
                  </div>

                  <div class="col-md-4 mb-3">
                    <label for="Mean_Grade" class="form-label"
                      >Mean Grade <span class="text-danger">*</span></label
                    >
                    <select
                      class="form-select"
                      id="Mean_Grade"
                      [(ngModel)]="MEAN_GRADE"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="">Select Grade</option>
                      <option *ngFor="let item of grades" [value]="item.AMT_ID">
                        {{ item.GRADE }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Examination_Number" class="form-label"
                      >Examination Number
                      <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Examination_Number"
                      placeholder="Level of Study"
                      [(ngModel)]="EXAM_REG_NO"
                      [ngModelOptions]="{ standalone: true }"
                    />
                    <div class="form-text">e.g KCSE Index Number</div>
                  </div>

                  <div class="col-md-6 mb-3">
                    <label for="formFile" class="form-label"
                      >Upload CERTIFIED Copies<span class="text-danger">*</span
                      >/span></label
                    >
                    <div class="input-group">
                      <input
                        type="file"
                        [(ngModel)]="UPLOAD_1"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="changeFile($event, 1)"
                        class="form-control"
                        #fileInputRefupload1
                      />
                      <span
                        class="input-group-text"
                        style="
                          cursor: pointer;
                          background-color: #ff7f50;
                          color: white;
                        "
                        id="basic-addon2"
                        (click)="removeFile('1')"
                        >Remove</span
                      >
                    </div>
                    <div class="text-blue">
                      <small
                        >(The previous education support documents should be
                        scanned and uploaded.)</small
                      >
                    </div>
                    <p *ngIf="upload_1 != ''">
                      {{ upload_1 }}
                    </p>
                    <div *ngIf="isProgressTwo" class="progress my-3">
                      <div
                        class="progress-bar progress-bar-info progress-bar-striped"
                        role="progressbar"
                        attr.aria-valuenow="{{ progressTwo }}"
                        aria-valuemin="0"
                        aria-valuemax="100"
                        [ngStyle]="{ width: progressTwo + '%' }"
                      >
                        {{ progressTwo }}%
                      </div>
                    </div>
                  </div>
                </div>

                <div class="text-center mb-4">
                  <button
                    type="button"
                    class="btn btn-sm btn-save"
                    (click)="add()"
                  >
                    Add and Save
                  </button>
                </div>
              </form>

              <div class="table-responsive" *ngIf="educationData.length">
                <table
                  class="table-striped table-bordered mt-2 pt-2 table-sm small"
                  style="width: 100%"
                >
                  <thead
                    class="top"
                    style="background-color: #624fd7; color: #ffffff"
                  >
                    <tr>
                      <th>Institution</th>
                      <th>Level of Study</th>
                      <th>Qualifications Attained</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>KCSE Index Number</th>
                      <th>Grade</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of educationData; let i = index">
                      <td>{{ row?.INSTITUTION_NAME }}</td>
                      <td>{{ row?.LEVEL_OF_STUDY }}</td>
                      <td>
                        <select
                          [hidden]="row.QUALIFICATION != ''"
                          class="form-select form-select-sm"
                          disabled
                          [(ngModel)]="row.QUALIFICATION_ID"
                          [ngModelOptions]="{ standalone: true }"
                        >
                          <option value="">Select</option>
                          <option value="1">KCSE</option>
                          <option value="2">IGCSE</option>
                          <option value="3">GCSE</option>
                          <option value="4">IB</option>
                          <option value="5">Others</option>
                        </select>
                        <div class="pt-2" [hidden]="row.QUALIFICATION == ''">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            disabled
                            [value]="row.QUALIFICATION"
                          />
                        </div>
                      </td>
                      <td>{{ row?.FROMPERIOD_YEAR }}</td>
                      <td>{{ row?.TOPERIOD_YEAR }}</td>
                      <td>{{ row?.EXAM_REG_NO }}</td>
                      <td>{{ row.GRADE }}</td>
                      <td style="display: flex">
                        <button
                          class="btn btn-save btn-sm"
                          (click)="editdata(row, 'edu')"
                        >
                          Edit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          class="btn btn-danger btn-sm"
                          (click)="remove(i)"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center" *ngIf="!educationData.length">
                No Records to display
              </div>
              <div class="text-end pt-2">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="previousStep()"
                >
                  Previous
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="saveEducationData(signUpForm)"
                  [disabled]="!educationData.length"
                >
                  Next
                </button>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="apptype == '2' && currentStep === 3">
            <!-- Recommendations -->
            <form *ngIf="isRecommondations">
              <form class="form-container cstm_stepper">
                <h6 class="mb-2 mt-3 title_stepper">Recommendations</h6>

                <div class="row">
                  <div class="col-md-4 mb-3">
                    <label for="Full_Name:" class="form-label"
                      >Full Name:
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="Full_Name:"
                      placeholder="Full Name:"
                      [(ngModel)]="NAME"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Organization:" class="form-label"
                      >Organization</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="Organization"
                      placeholder="Organization:"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                      [(ngModel)]="ORGANIZATION"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="City_Town" class="form-label">City/Town</label>
                    <input
                      class="form-control"
                      type="text"
                      id="City_Town"
                      placeholder="City/Town"
                      onkeypress="return /[a-a-zA-Z'-'\s]/i.test(event.key)"
                      [(ngModel)]="CITY_TOWN"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Nationality:" class="form-label"
                      >Nationality:</label
                    >

                    <select
                      class="form-select"
                      [(ngModel)]="NATIONALITY"
                      [ngModelOptions]="{ standalone: true }"
                    >
                      <option value="0">Select</option>
                      <option
                        *ngFor="let item of nationalityList"
                        [value]="item.NAN_ID"
                      >
                        {{ item.NAN_CODE }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-4 mb-3">
                    <label for="Recommender_email" class="form-label"
                      >Recommender email</label
                    >
                    <input
                      class="form-control"
                      type="email"
                      id="Recommender_email"
                      placeholder="Recommender email"
                      (change)="validateEmail($event, 'sponserEmail')"
                      [(ngModel)]="EMAIL_ADDRESS"
                      [ngModelOptions]="{ standalone: true }"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-8">
                    <label for="dob" class="form-label"
                      >Mobile Number <span class="text-danger">*</span></label
                    >
                    <div class="d-flex gap-2">
                      <select
                        [(ngModel)]="COUNTRY_ID"
                        [ngModelOptions]="{ standalone: true }"
                        (change)="changeCountryCode($any($event.target)?.value)"
                        class="form-select"
                      >
                        <option value="0">Select</option>
                        <option
                          *ngFor="let item of country"
                          [value]="item.COUNTRY_ID"
                        >
                          {{ item.COUNTRY_NAME }}
                        </option>
                      </select>
                      <select
                        class="form-control"
                        [(ngModel)]="MOBILENO_CODE_ID"
                        [ngModelOptions]="{ standalone: true }"
                        disabled
                      >
                        <option value="">Select country code</option>
                        <option
                          *ngFor="let item of country"
                          [value]="item.COUNTRY_ID"
                        >
                          {{ item.COUNTRY_CONNECTING_CODE }}
                        </option>
                      </select>
                      <input
                        class="form-control large-width"
                        type="text"
                        id="712455789"
                        (keypress)="onKeyPress($event, 4)"
                        maxlength="12"
                        [(ngModel)]="MOBILE_NUMBER"
                        [ngModelOptions]="{ standalone: true }"
                      />
                    </div>
                  </div>
                </div>
                <div class="text-center mb-4 pt-4">
                  <button
                    type="button"
                    class="btn btn-sm btn-save"
                    (click)="addRefreeData()"
                  >
                    Add and Save
                  </button>
                </div>
              </form>
              <div *ngIf="RefreeData.length" class="table-responsive">
                <table
                  datatable
                  class="table-striped table-bordered mt-2 pt-2 table-sm small"
                  style="width: 100%"
                >
                  <thead
                    class="top"
                    style="background-color: #624fd7; color: #ffffff"
                  >
                    <tr>
                      <th>Full Name</th>
                      <th>Organization</th>
                      <th>Mobile Number</th>
                      <th>Recommender email</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let row of RefreeData; let i = index">
                      <td>{{ row?.NAME }}</td>
                      <td>{{ row?.ORGANIZATION }}</td>
                      <td>
                        {{ row?.COUNTRY_CONNECTING_CODE }}
                        {{ row?.MOBILE_NUMBER }}
                      </td>
                      <td>{{ row?.EMAIL_ADDRESS }}</td>
                      <td style="display: flex">
                        <button
                          class="btn btn-save btn-sm"
                          (click)="editdata(row, 'refre')"
                        >
                          Edit
                        </button>
                        &nbsp;&nbsp;
                        <button
                          class="btn btn-danger btn-sm"
                          (click)="removeRefreeData(i)"
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center" *ngIf="!RefreeData.length">
                No Records to display
              </div>
              <div class="text-end pt-2">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="previousStep()"
                >
                  Previous
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  (click)="saveRecommondationData()"
                >
                  Next
                </button>
              </div>
            </form>
          </ng-container>

          <ng-container *ngIf="currentStep === (apptype == '2' ? 4 : 3)">
            <!-- Review and Submit -->
            <form class="form-container cstm_stepper">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <label for="dob" class="form-label"
                    >How did you hear about OUK?
                  </label>
                  <div class="d-flex gap-2">
                    <select
                      [(ngModel)]="selectedReferral"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeReferral($any($event.target)?.value)"
                      class="form-select"
                    >
                      <option value="">Select</option>
                      <option
                        *ngFor="let item of referrals"
                        [value]="item.REF_SOCIALMEDIA_ID"
                      >
                        {{ item.REF_NAME }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <label for="Recommender_email" class="form-label"
                  >Save and Review</label
                >
                <div
                  style="
                    background-color: #d1d1d6;
                    text-align: center;
                    padding: 30px;
                    margin-bottom: 5px;
                  "
                >
                  <button
                    type="submit"
                    class="btn btn-sm btn-save"
                    data-bs-target="#applicationPopup"
                    data-bs-toggle="modal"
                    (click)="update(signUpForm, 4, 0)"
                  >
                    Save and Review
                  </button>
                </div>
                <!-- <div style="background-color: #f0f9ff; padding: 5px">
                    <span
                      >NOTE: Remember that once you submit your application you
                      cannot make any additional changes or view the
                      application</span
                    >
                  </div> -->
              </div>
            </form>
            <div class="text-end pt-2">
              <button
                type="button"
                class="btn btn-secondary"
                (click)="previousStep()"
              >
                Previous
              </button>
              <button
                type="button"
                class="btn btn-save"
                [disabled]="userDetails?.result?.is_submit == 3"
                (click)="update(signUpForm, 4, 1)"
                *ngIf="userDetails?.result?.is_submit != 5 && aprvId != 4"
              >
                Update
              </button>
              <button
                type="button"
                class="btn btn-save"
                (click)="update(signUpForm, 8)"
                *ngIf="userDetails?.result?.is_submit == 5 && aprvId == 4"
              >
                Enroll
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          id="updateClose"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <!-- <button type="button" class="btn btn-info btn-sm" [disabled]="userDetails?.result?.is_submit==3"
                      (click)="update(signUpForm,4,1)"
                      *ngIf="userDetails?.result?.is_submit!=5 && aprvId != 4">Update</button>
                  <button type="button" class="btn btn-info btn-sm" (click)="update(signUpForm,8)"
                      *ngIf="userDetails?.result?.is_submit==5 && aprvId == 4">Enroll</button> -->
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" id="commentsModel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <h6>{{ LevelTwoAdminComments }}</h6>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" id="userlogs">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"></h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div *ngIf="userLogData.length">
          <table
            id="patreggraph"
            datatable
            class="table-striped table-bordered mt-2 pt-2 table-sm small"
            style="width: 100%"
          >
            <thead
              class="top"
              style="background-color: #624fd7; color: #ffffff"
            >
              <tr>
                <th>User name</th>
                <th>Status name</th>
                <th>Tracking Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of userLogData">
                <td>{{ item.USERNAME }}</td>
                <td>{{ item.APPLICATION_STATUSNAME }}</td>
                <td>
                  {{ item.APPL_TRACKING_DATE | date : "yyyy-MM-dd HH:mm:ss" }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="!userLogData.length">
          No Records to display
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
