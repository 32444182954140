import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-payment-success",
  templateUrl: "./payment-success.component.html",
  styleUrls: ["./payment-success.component.css"],
})
export class PaymentSuccessComponent implements OnInit {
  getId: any;
  constructor(private route: Router, private active: ActivatedRoute) {
    this.active.queryParams.subscribe((res: any) => {
      this.getId = res.id;
    });
  }

  ngOnInit(): void {}
  homePage() {
    if (this.getId == 1) {
      this.route.navigate(["/HOME/myApplication"]);
    } else if (this.getId == 2) {
      this.route.navigate(["/login"]);
      localStorage.clear();
      return sessionStorage.clear();
      // return this.route.navigate(['/HOME/components/myEnrolment/4813']);
    } else if (this.getId == 4) {
      this.route.navigate(["HOME/registration/transferApplication/4875"]);
    } else if (this.getId == 5) {
      this.route.navigate(["/HOME/registration/CreditTransfer/4873"]);
    } else if (this.getId == 7) {
      this.route.navigate(["/HOME/graduation/graduationFee/4873"]);
    } else if (this.getId == 9) {
      this.route.navigate(["/HOME/components/myShortCourses"]);
    } else {
      return this.route.navigate(["/HOME/courseSetup/StudentMyCourses/456"]);
    }
    // this.route.navigate(['/HOME/courseSetup/StudentMyCourses'])/HOME/components/myEnrolment
  }
}
