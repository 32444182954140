import { Component, OnInit } from "@angular/core";
import { DashboardService } from "../services/dashboard.service";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterEvent,
} from "@angular/router";
import { CommonService } from "../services/common.service";
import { filter } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "../pages/base.component";

declare let $: any;
@Component({
  selector: "app-maincontent",
  templateUrl: "./maincontent.component.html",
  styleUrls: ["./maincontent.component.css"],
})
export class MaincontentComponent extends BaseComponent implements OnInit {
  cources: [] = [];
  newsList = [];
  news = {};
  isNews = false;
  isPieChart: Boolean = false;
  totalAmount: number = 0;
  registration_status: any = localStorage.getItem("registration_status");
  paidAmount: number = 0;
  balance: number = 0;
  studentPresent: number = 0;
  headerText: string = "";
  studentAbsent: number = 0;
  staffPresent: number = 0;
  staffAbsent: number = 0;
  roleId: any = localStorage.getItem("RoleId");
  userName = localStorage.getItem("Username")?.toUpperCase();
  roleName = localStorage.getItem("RoleName");
  superAdminDashboardData: any = {};
  AdminDashboard: any = {};
  staffAttendancePresent: any = {};
  staffAttendanceAbsent: any = {};
  applicationInPorgrs: any = {};
  not_registered: any = {};
  in_review: any = {};
  fees: any = {};
  studentAttendancePresent: any = {};
  studentAttendanceAbsent: any = {};
  kuccps_yes: any = {};
  kuccps_no: any = {};
  library: any = {};
  inventory: any = {};
  feedback: any = {};
  users: any = {};
  events: any = {};
  courses: any = {};
  totalStaff: any = {};
  totalStudent: any = {};
  signedUp: any = {};
  applied: any = {};
  admitted: any = {};
  rejected: any = {};
  registered: any = {};
  schl_of_agri: any = {};
  studentfeereceivables: any = {};
  schl_of_bus_eco: any = {};
  schl_of_edu: any = {};
  schl_of_scnc_tech: any = {};
  transferList: Array<any> = [];
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public lineChartLabels = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
  ];
  public lineChartType = "line";
  public lineChartLegend = true;
  public lineChartData = [
    { data: [75, 49, 89, 31, 86, 35, 50], label: "Present" },
    { data: [48, 38, 65, 39, 66, 17, 80], label: "Absent" },
  ];

  public barChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: true,
  };
  public barChartLabels = [
    "2000",
    "2001",
    "2002",
    "2003",
    "2004",
    "2005",
    "2006",
  ];
  public barChartType = "bar";
  public barChartLegend = true;
  public barChartData = [
    { data: [75, 49, 89, 31, 86, 35, 50], label: "Series A" },
    { data: [48, 38, 65, 39, 66, 17, 80], label: "Series B" },
  ];

  public pieChartLabels: string[] = ["Present", "Absent"];
  public pieChartData = [this.staffPresent, this.staffAbsent];
  public pieChartType = "pie";

  public pieChartLabels1: string[] = ["Present", "Absent"];
  public pieChartData1 = [this.studentPresent, this.studentAbsent];
  public pieChartType1 = "pie";

  public doughnutChartLabels = ["Total Amount", "Paid Amount", "Balance"];
  public doughnutChartData = [this.totalAmount, this.paidAmount, this.balance];
  public doughnutChartType = "doughnut";

  public radarChartLabels = ["Q1", "Q2", "Q3", "Q4"];
  public radarChartData = [
    { data: [80, 83, 94, 87], label: "2017" },
    { data: [90, 150, 121, 65], label: "2018" },
  ];
  public radarChartType = "radar";
  responseData: Array<any> = [];
  Bachelor_of_Agri_Technology_and_Food_Systems: any;
  Bachelor_of_Business_and_Entrepreneurship: any;
  Bachelor_of_Data_Science: any;
  Bachelor_of_Economics_and_Statistics: any;
  Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics: any;
  Bachelor_of_Technology_Education: any;
  PG_Diploma_in_Leadership_and_Accountability: any;
  PG_Diploma_in_Learning_Design_and_Technology: any;
  enroll: any;
  notEnroll: any;
  sentBack: any;
  Intiated: any;
  txt: boolean;
  Admitted: boolean;

  refreshed: boolean = false;
  reload: string = "";
  transferDescription: any;
  title: any;
  constructor(
    private DashboardService: DashboardService,
    private route: Router,
    commonService: CommonService,
    toastr: ToastrService,
    private active: ActivatedRoute
  ) {
    super(commonService, toastr);
    this.roleId = atob(this.roleId);
    if (this.roleId == 3 || this.roleId == 2 || this.roleName == "HOD") {
      this.loadCourse();
    }
    this.getCource();
    this.getDashboard();
    // this.calender();
    this.getNewsList();
    this.LoadSuperAdminDashboard();
    this.loadAdminDashboard();
    this.logout();
  }

  course: any = {
    isSessions: false,
    isClasses: true,
  };
  data: any = {};
  ngOnInit(): void {
    this.loadReportDtOptions();
    this.interIntraTransferNotificationList();
    this.active.queryParams.subscribe((res: any) => {
      this.reload = res.req;
      // if (this.reload === ) {
      this.refresh(this.reload);
      // }
    });
  }
  refresh(val) {
    if (!sessionStorage.getItem("refreshedRole")) {
      sessionStorage.setItem("refreshedRole", val);
      if (sessionStorage.getItem("refreshedRole") == val)
        window.location.reload();
    } else {
      // Optionally, clear the session storage key after reloading to allow future reloads
      sessionStorage.removeItem("refreshedRole");
    }
  }
  loadReportDtOptions() {
    this.dtOptions = {
      dom: "Bfrtip",
      buttons: [
        {
          extend: "excel",
          filename: "Registration Report",
        },
        {
          extend: "pdfHtml5",
          filename: "Registration Report",
          title: "Registration Report",
          orientation: "landscape",
          pageSize: "LEGAL",
          text: "PDF",
        },
      ],
      order: [],
    };
  }
  logout() {
    this.CommonService.activateSpinner();
    let value = localStorage.getItem("UserId");
    let roleId = localStorage.getItem("RoleId");
    let username = !localStorage.getItem("Username");
    if (
      value == "null" ||
      value == (0 || "" || "0") ||
      roleId == "null" ||
      roleId == (0 || "" || "0") ||
      username
    ) {
      this.route.navigate(["/login"]);
    }
    this.CommonService.deactivateSpinner();
  }
  loadCourse() {
    this.CommonService.activateSpinner();
    this.CommonService.getCall(
      `Courses/GetCourses/${localStorage.getItem(
        "UserId"
      )}/${localStorage.getItem("RoleId")}`
    ).subscribe(
      (res: any) => {
        this.cources = res;
        this.CommonService.deactivateSpinner();
      },
      (e) => {
        this.CommonService.deactivateSpinner();
      }
    );
  }

  registrationDetails(status: number, txt: string, cId: any) {
    this.activeSpinner();
    this.headerText = "";
    this.headerText = txt;
    let headers = [
      "Enroled",
      "Initiated",
      "Not Enroled",
      "In Review",
      "Admitted",
      "Accepted",
    ];
    this.txt = headers.includes(txt);
    this.Admitted = txt.includes("Admitted");
    this.responseData = [];
    let payload = {
      TNT_CODE: localStorage.getItem("TenantCode"),
      STATUS: status,
      CID: cId,
      SchoolId: localStorage.getItem("SchoolId"),
      DepartmentId: localStorage.getItem("DepartmentId"),
    };
    this.CommonService.postCall(
      "Registration/Loadregistrationbreakupdetails",
      payload
    ).subscribe(
      (res: any) => {
        this.responseData = res;
        this.deactivateSpinner();
      },
      (e: any) => {
        this.deactivateSpinner();
      }
    );
  }

  getCource() {
    this.CommonService.activateSpinner();
    this.DashboardService.getCource().subscribe(
      (data: any) => {
        this.course = { ...this.course, ...data };

        this.stopSpinner();
      },
      (e) => {
        this.stopSpinner();
      }
    );
  }
  toggleCource(key, collapse) {
    this.course.isSessions = false;
    this.course.isClasses = false;
    this.course[key] = !collapse;
  }
  navigate(data, url) {
    delete data.Name;
    this.route.navigate([url], { queryParams: data });
  }
  getDashboard() {
    this.CommonService.activateSpinner();
    this.DashboardService.loadDashboard().subscribe(
      (res: any) => {
        this.stopSpinner();
        this.data = res ? res : {};
      },
      (e) => {
        this.stopSpinner();
      }
    );
  }

  stopSpinner() {
    this.CommonService.deactivateSpinner();
  }
  joinSession(item) {
    if (item.GooglMeet_URL.includes("https://meet.google.com")) {
      return window.open(item.GooglMeet_URL);
    } else {
      this.CommonService.activateSpinner();
      let payload = {
        UserId: localStorage.getItem("UserId"), //RoleId,TenantCode,UserId,Username
        TenantCode: localStorage.getItem("TenantCode"),
        Username: localStorage.getItem("Username"),
        APPOINTMENT_ID: item?.URL,
        // "RoleId": localStorage.getItem('RoleId'),
        Decrypt_Role: localStorage.getItem("RoleId"),

        CourseScheduleId: 1,
      };

      this.CommonService.postCall("AVService/Joinurl", payload).subscribe(
        (res) => {
          this.CommonService.deactivateSpinner();
          if (res.JoinUrl.includes("https")) {
            window.open(res.JoinUrl, "_blank");
          } else {
            // this.toastr.warning("Host Not joined");
            this.toastr.warning(res.JoinUrl);
          }
        },
        (err) => {
          this.toastr.warning("Please Try Later");
          this.CommonService.deactivateSpinner();
        }
      );
    }
  }
  // calender() {
  //   $(function () {
  //     $(".b-notes").notes(".b-notes");

  //     $("#calendar").datepicker({
  //       todayHighlight: true,
  //       weekStart: 1
  //     }).on({

  //       'changeDate': function (e) {

  //         if (typeof (e.date) == "undefined") return false;

  //         var milliseconds = Date.parse(e.date);

  //         setCelendarDay(milliseconds);
  //       }

  //     });

  //     var today = new Date();
  //     var milliseconds = Date.parse(today.toString());

  //     setCelendarDay(milliseconds);

  //     function setCelendarDay(milliseconds) {
  //       var list = $(".b-notes__list");
  //       var title = $(".b-app__title");

  //     }
  //   });
  // }
  // var date = new Date(milliseconds).format("dd/mm/yyyy");
  // var formatTitle = new Date(milliseconds).format("dddd, <b>d mmmm</b>");

  close() {}

  getNewsList() {
    this.newsList = [];
    let payload = {
      TNT_CODE: localStorage.getItem("TenantCode"),
      ROLE_NAME: this.roleName,
    };
    this.CommonService.postCall("News/GetNewsList", payload).subscribe(
      (response: any) => {
        this.newsList = response;
      }
    );
  }

  getNews(EVENT_ID) {
    this.news = {};
    let payload = {
      EVENT_ID: EVENT_ID,
    };
    this.CommonService.postCall("News/Get", payload).subscribe(
      (response: any) => {
        this.isNews = true;
        this.news = response;
      }
    );
  }
  // surveys() {
  //   this.route.navigate(['/HOME/surveys/93'])
  // }
  // polls() {
  //   this.route.navigate(['/HOME/poll/94'])
  // }

  LoadSuperAdminDashboard() {
    this.superAdminDashboardData = {};
    this.isPieChart = true;
    this.CommonService.getCall(
      `Dashboard/LoadSuperAdminDashboard/${localStorage.getItem("TenantCode")}`
    ).subscribe((res: any) => {
      this.superAdminDashboardData = res;
      this.totalAmount = res.studentfeereceivables[0].total_amount;
      this.paidAmount = res.studentfeereceivables[0].paid_amount;
      this.balance = res.studentfeereceivables[0].balance;
      this.doughnutChartData = [
        this.totalAmount,
        this.paidAmount,
        this.balance,
      ];
      this.staffPresent = res.staffattendance.find(
        (e: any) => e.staff_attendance_status == "Present"
      )?.staff_count;
      this.staffAbsent = res.staffattendance.find(
        (e: any) => e.staff_attendance_status == "Absent"
      )?.staff_count;
      this.pieChartData = [this.staffPresent, this.staffAbsent];
      this.studentPresent = res.studentattendance.find(
        (e: any) => e.student_day_wise_status == "Present"
      )?.student_count;
      this.studentAbsent = res.studentattendance.find(
        (e: any) => e.student_day_wise_status == "Absent"
      )?.student_count;
      this.pieChartData1 = [this.studentPresent, this.studentAbsent];
    });
  }

  loadAdminDashboard() {
    this.AdminDashboard = {};
    this.CommonService.getCall(
      `Dashboard/LoadAdminOrSuperadminDashboard/${localStorage.getItem(
        "RoleId"
      )}/${localStorage.getItem("TenantCode")}`
    ).subscribe((res: any) => {
      this.AdminDashboard = res;
      this.studentfeereceivables = res["studentfeereceivables"];
      this.staffAttendancePresent = res["staffattendance"][0];
      this.staffAttendanceAbsent = res["staffattendance"][2];
      this.studentAttendancePresent = res["studentattendance"][0];
      this.studentAttendanceAbsent = res["studentattendance"][1];
      this.library = res["library"];
      this.inventory = res["inventory"];
      this.feedback = res["feedback"];
      this.users = res["users1"];
      this.events = res["Event"];
      this.courses = res["Courese"];
      this.totalStaff = res["Totalstaff"];
      this.totalStudent = res["TotalStudents"];
      this.signedUp = res["SignedUp"];
      this.applied = res["Applied"];
      this.admitted = res["Admitted"];
      this.rejected = res["Rejected"];
      this.registered = res["Registered"];
      this.applicationInPorgrs = res["Application_in_Progress"];
      this.in_review = res["In_Review"];
      this.not_registered = res["Not_Registered"];
      this.kuccps_yes = res["Kuccps_Yes"];
      this.kuccps_no = res["Kuccps_No"];
      this.schl_of_agri = res["School_of_Agriculture"];
      this.schl_of_bus_eco = res["School_of_Business_and_Economics"];
      this.schl_of_edu = res["School_of_Education"];
      this.schl_of_scnc_tech = res["School_of_Science_and_Technology"];

      this.Bachelor_of_Agri_Technology_and_Food_Systems =
        res["Bachelor_of_Agri_Technology_and_Food_Systems"];
      this.Bachelor_of_Business_and_Entrepreneurship =
        res["Bachelor_of_Business_and_Entrepreneurship"];
      this.Bachelor_of_Data_Science = res["Bachelor_of_Data_Science"];
      this.Bachelor_of_Economics_and_Statistics =
        res["Bachelor_of_Economics_and_Statistics"];
      this.Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics =
        res["Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics"];
      this.Bachelor_of_Technology_Education =
        res["Bachelor_of_Technology_Education"];
      this.PG_Diploma_in_Leadership_and_Accountability =
        res["PG_Diploma_in_Leadership_and_Accountability"];
      this.PG_Diploma_in_Learning_Design_and_Technology =
        res["PG_Diploma_in_Learning_Design_and_Technology"];
      this.enroll = res["Enroll"];
      this.notEnroll = res["Not_Enrolled"];
      this.sentBack = res["Sentback"];
      this.Intiated = res["Initiate"];
    });
  }
  interIntraTransferNotificationList() {
    this.transferList = [];
    this.CommonService.postCall(
      "TransferNotifications/TransferNotificationList",
      { tnt_code: this.TenantCode }
    ).subscribe((res: any) => {
      this.transferList = res;
    });
  }
  getTransfer(obj: any) {
    this.transferDescription = obj.DESCRIPTION;
    this.title = obj.TITTLE;
  }

  resubmit() {
    this.route.navigate(["/vendorRegistration"]);
  }

  backtoNewDashboard() {
    this.route.navigate(["/HOME/adminDashboard"]);
  }
}
