<div
  class="wrapper"
  style="padding-top: 5.5rem; padding-left: 30px; padding-bottom: 30px"
>
  <div class="container-fluid">
    <div class="p-2">
      <div class="row">
        <div class="uid pe-lg-4 ps-lg-4">
          <div class="row">
            <div class="col-xl-2"></div>
            <div class="col-xl-10">
              <div class="row">
                <!-- <div class="col-xl-2"></div> -->
                <div class="col-xl-9">
                  <div class="mb-3">
                    <span class="date_student">{{
                      currentDate | date : "dd MMM, yyyy"
                    }}</span>
                    <h2 class="title_student">
                      <img
                        src="../../../../assets/images/admin-dean-dashboard/waving-hand.png"
                      />
                      Hello, {{ dashboardResponse?.NAME }}
                    </h2>
                    <span
                      >Welcome back, your gateway to learning and growth</span
                    >
                  </div>

                  <div class="row">
                    <div class="col-xl-7 mb-3">
                      <div class="card cst_1 h-100">
                        <div class="card-body">
                          <div class="">
                            <div class="d-flex justify-content-between mb-2">
                              <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                              <div class="">
                                <h4 class="title_card_1">
                                  <span>My Programme</span>
                                  <span
                                    class="badge bg-light text-success"
                                    *ngIf="isApplicant != '1'"
                                  >
                                    Active
                                  </span>
                                  <span
                                    class="badge bg-light text-secondary"
                                    *ngIf="
                                      isApplicant == '1' &&
                                      !dashboardResponse?.COURSE_CATEGORY_NAME
                                    "
                                    >No Application</span
                                  >
                                </h4>
                              </div>
                            </div>
                            <span *ngIf="isApplicant != '1'">
                              <p
                                style="
                                  font-size: 14px !important;
                                  color: #6c757d;
                                "
                              >
                                {{ dashboardResponse?.COURSE_CATEGORY_NAME }}
                              </p>
                              <p class="fw-bold">
                                Current Programme
                                <i class="bi bi-arrow-down ms-1"></i>
                              </p>
                              <h6 class="cstm_stu_title">
                                {{ dashboardResponse?.COURSE_NAME }}
                              </h6>
                              <div class="d-flex justify-content-between mb-2">
                                <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                              </div>
                            </span>
                            <div
                              class="text-center"
                              *ngIf="
                                isApplicant == '1' &&
                                !dashboardResponse?.COURSE_CATEGORY_NAME
                              "
                              style="padding-top: 10%"
                            >
                              <img
                                src="../../assets/img/Norecords/Icon 11.png"
                                alt=""
                              /><br />
                              No Application Yet
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 mb-3">
                      <div class="card cst_1 h-100">
                        <div
                          class="card-body csr_stu_crd_bg text-white"
                          style="border-radius: 8px"
                        >
                          <div
                            class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                          >
                            <span>My Timetable</span>
                          </div>
                          <div class="float-end pt-1">
                            <a
                              href="/HOME/timetable/viewTimetableForStndt"
                              class="text-decoration-none"
                              style="color: #ea8c5e"
                            >
                              View All<i
                                class="bi bi-chevron-right ms-1 pt-1"
                                style="font-size: 11px"
                              ></i
                            ></a>
                          </div>
                          <div class="clearfix mb-2"></div>
                          <div *ngIf="isApplicant != '1'">
                            <div
                              *ngFor="
                                let item of showAll
                                  ? dashboardResponse?.dLC_MyTimeTable
                                  : (dashboardResponse?.dLC_MyTimeTable
                                    | slice : 0 : 1)
                              "
                            >
                              <div class="mb-3">
                                Today's Class
                                <strong>{{ item?.DAY.split("-")[1] }}</strong>
                              </div>

                              <div class="card-cstm">
                                <div class="row justify-content-between mb-3">
                                  <div class="col-xl-3">
                                    <span
                                      class="bage rounded-circle align-items-center d-flex"
                                      style="
                                        width: 50px;
                                        height: 50px;
                                        background-color: #efefef;
                                      "
                                    >
                                      <i
                                        class="bi bi-calendar icon-style"
                                        style="
                                          font-size: 18px;
                                          margin: 0px auto;
                                        "
                                      ></i
                                    ></span>
                                  </div>
                                  <div class="col-xl-9">
                                    <span
                                      class="mb-1"
                                      style="font-weight: 600"
                                      >{{ item?.SUBJECT }}</span
                                    >
                                  </div>
                                </div>
                                <div class="float-end">
                                  <span style="color: #ea8c5e">
                                    Prof.
                                    <strong class="text-white">{{
                                      item?.NAME
                                    }}</strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="text-center"
                            *ngIf="isApplicant == '1'"
                            style="padding-top: 10%"
                          >
                            <img
                              src="../../assets/img/Norecords/Icon 11.png"
                              alt=""
                            /><br />
                            No Class Found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-xl-7 mb-3">
                      <div class="card border-0 h-100">
                        <div class="card-body">
                          <div class="d-flex align-items-center">
                            <span
                              class="card_title h6 float-start pt-1 mb-3 fw-bold"
                              >Course Progress</span
                            >
                          </div>
                          <!-- <small style="color: grey; font-size: 14px"
                            >Track your semester learning journey and milestones
                          </small> -->
                          <div class="row">
                            <!-- <div
                              class="col-lg-12 text-center"
                              *ngIf="isApplicant != '1'"
                            >
                              <img
                                src="../../../../assets/images/admin-dean-dashboard/Dashboards UI_img_11.jpg"
                                class="img-fluid"
                              />
                            </div> -->
                            <!-- *ngIf="isApplicant == '1'" -->
                            <div class="text-center" style="padding-top: 10%">
                              <img
                                src="../../assets/img/Norecords/Icon 11.png"
                                alt=""
                              /><br />
                              No Course Found
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-5 mb-3">
                      <div class="card border-0 h-100">
                        <div class="card-body">
                          <div class="d-flex align-items-center">
                            <span
                              class="card_title h6 float-start pt-1 mb-3 fw-bold"
                              >Overall Progress</span
                            >
                          </div>
                          <!-- <small style="color: grey; font-size: 14px"
                            >Trock your semester overall scote based on your
                            course interactions
                          </small> -->
                          <div class="row">
                            <!-- <div
                              class="col-lg-12 text-center"
                              *ngIf="isApplicant != '1'"
                            >
                              <img
                                src="../../../../assets/images/admin-dean-dashboard/Dashboards UI_img_12.jpg"
                                class="img-fluid"
                              />
                            </div> -->

                            <!-- *ngIf="isApplicant == '1'" -->

                            <div class="text-center" style="padding-top: 10%">
                              <img
                                src="../../assets/img/Norecords/Icon 11.png"
                                alt=""
                              /><br />
                              No Progress to show
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-7 mb-3">
                      <div class="card cst_1 h-100">
                        <div class="card-header bg-transparent border-0">
                          <div
                            class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                          >
                            <span>Exam Board</span>
                          </div>
                          <!-- <div class="float-end pt-1"><a href="@" class="text-decoration-none text-danger">view all<i class="bi bi-chevron-right ms-1 pt-1" style="font-size:11px"></i></a></div> -->
                        </div>
                        <div class="card-body">
                          <div
                            class="table-responsive"
                            *ngIf="
                              dashboardResponse?.dLC_ExamBoardCourses.length
                            "
                          >
                            <table
                              class="table table-bordered table-striped table-sm table_stu_stm"
                            >
                              <thead
                                style="
                                  background-color: #047b90 !important;
                                  color: white;
                                "
                              >
                                <tr>
                                  <th>Code</th>
                                  <th>Course Title</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  *ngFor="
                                    let item of dashboardResponse?.dLC_ExamBoardCourses
                                  "
                                >
                                  <td>{{ item.SUBJECT_CODE }}</td>
                                  <td>{{ item.SUBJECT_NAME }}</td>
                                  <td>
                                    <span class="badge badge_1">{{
                                      item.EXAMBOARD_STATUS
                                    }}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div
                            class="text-center"
                            *ngIf="
                              isApplicant == '1' &&
                              !dashboardResponse?.dLC_ExamBoardCourses.length
                            "
                          >
                            <img src="../../assets/img/Norecords/Icon 11.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 mb-3">
                      <div class="card cst_1 h-100">
                        <div class="card-header bg-transparent border-0">
                          <div
                            class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                          >
                            <span>Fees Board</span>
                          </div>
                          <!-- <div class="float-end pt-1"><a href="@" class="text-decoration-none text-danger">view fees<i class="bi bi-chevron-right ms-1 pt-1" style="font-size:11px"></i></a></div> -->
                        </div>
                        <div class="card-body">
                          <div *ngIf="isApplicant != '1'">
                            <div
                              class="d-flex align-items-center p-3"
                              style="
                                background-color: #eaf7f4;
                                border-radius: 8px;
                              "
                            >
                              <div class="me-3">
                                <div
                                  style="
                                    width: 50px;
                                    height: 50px;
                                    background-color: #037c92;
                                    border-radius: 50%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                  "
                                >
                                  <img
                                    src="../../../../assets/images/admin-dean-dashboard/Imag_8.png"
                                    alt="Receipt Icon"
                                    style="width: 24px; height: 24px"
                                  />
                                </div>
                              </div>
                              <div>
                                <h5
                                  class="badge badge_3 m-0 text-success"
                                  style="font-size: 20px"
                                >
                                  {{
                                    dashboardResponse?.dLC_FeesBoard[0]
                                      ?.Currency_type
                                  }}
                                  {{
                                    dashboardResponse?.dLC_FeesBoard[0]
                                      ?.available_amount
                                  }}
                                </h5>
                              </div>
                            </div>
                            <div class="mt-2">
                              <p class="m-0">
                                <span class="fw-bold">Fees Due: </span>
                                <span class="text-danger fw-bold"
                                  >{{
                                    dashboardResponse?.dLC_FeesBoard[0]
                                      ?.Currency_type
                                  }}
                                  {{
                                    dashboardResponse?.dLC_FeesBoard[0]
                                      ?.total_amount
                                  }}</span
                                >
                              </p>
                            </div>
                          </div>
                          <div
                            class="text-center"
                            *ngIf="isApplicant == '1'"
                            style="padding-top: 10%"
                          >
                            <img
                              src="../../assets/img/Norecords/Icon 11.png"
                              alt=""
                            /><br />
                            No Fees Found
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3">
                  <div class="card border-0 shadow-sm card-radius mb-3">
                    <div class="card-header bg-transparent border-0">
                      <div class="card_title h6 float-start pt-1 mb-0 fw-bold">
                        Quick Launch
                      </div>
                    </div>
                    <div class="card-body p-2">
                      <div class="row">
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              href="/HOME/components/myEnrolment/4742"
                              title="Student Registration"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_1.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              title="Graduation Fee Payment"
                              href="/HOME/graduation/graduationFee/5020"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_9.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              href="/HOME/eWallet/eWalletStd"
                              title="Fee Acounts"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_8.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              href="/HOME/registration/transferApplication/4875"
                              title="Inter/Intra Transfers"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_4.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              href="https://app.myloft.xyz/user/login?institute=clj72q7ocr9dq0d992icc9egm"
                              title="Library"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_3.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                        <div class="col-xl-4 col-4 mb-3">
                          <div class="d-flex align-items-center">
                            <a
                              class="text-decoration-none text-center mx-auto"
                              href="https://oukdev.samvaadpro.com/home/KNA/OUK-StudentRegistration-UserGuide.pdf"
                              title="User Guide"
                            >
                              <span
                                class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                                style="width: 50px; height: 50px"
                              >
                                <img
                                  src="../../../../assets/images/admin-dean-dashboard/Imag_2.png"
                                  class="img-fluid"
                                  style="
                                    width: 20px;
                                    height: 20px;
                                    margin: 0px auto;
                                  "
                              /></span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border-0 shadow-sm card-radius mb-3">
                    <div class="card-header bg-transparent border-0">
                      <div
                        class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                      >
                        <span>My Courses</span>
                      </div>
                      <div *ngIf="isApplicant != '1'">
                        <div class="float-end pt-1">
                          <a
                            href="javascript:void(0)"
                            (click)="toggleViewAll()"
                            class="text-decoration-none text-danger"
                          >
                            {{ showAll ? "View Less" : "View All" }}
                            <i
                              class="bi bi-chevron-right ms-1 pt-1"
                              style="font-size: 11px"
                            ></i>
                          </a>
                        </div>
                        <div class="card-body p-3 bac">
                          <ul class="list-unstyled">
                            <li
                              class="mb-1"
                              *ngFor="
                                let item of showAll
                                  ? dashboardResponse?.dLC_Mycourses
                                  : (dashboardResponse?.dLC_Mycourses
                                    | slice : 0 : 4)
                              "
                            >
                              <span class="text-ellipsis d-inline-block"
                                ><strong>{{ item.SUBJECT_CODE }}:</strong
                                >{{ item.SUBJECT_NAME }}</span
                              >
                            </li>
                            <!-- <li class="mb-1">
                              
                              <span class="text-ellipsis d-inline-block"><strong>MAT 101:</strong>Foundations of Mathematics</span>
                            </li>
                            <li class="mb-1">
                              
                              <span class="text-ellipsis d-inline-block"><strong>CSC 101:</strong>Introduction to Computing Systems</span>
                            </li>
                            <li class="mb-1">
                              
                              <span class="text-ellipsis d-inline-block"><strong>CSC 103:</strong>Introduction to Programming</span>
                            </li>
                            <li class="mb-1">
                              
                              <span class="text-ellipsis d-inline-block"><strong>CSF 123:</strong>Physics for Computer Systems</span>
                            </li> -->
                          </ul>
                          <span class="badge badge_3" style="font-size: 12px">{{
                            dashboardResponse?.COURSEYEAR_NAME
                          }}</span>
                          <br />

                          <span
                            class="badge badge_3"
                            style="font-size: 12px; padding-top: 2px"
                            >{{ dashboardResponse?.SEMESTER_NAME }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="text-center"
                        *ngIf="isApplicant == '1'"
                        style="padding-top: 10%"
                      >
                        <img
                          src="../../assets/img/Norecords/Icon 11.png"
                          alt=""
                        /><br />
                        No Courses Found
                      </div>
                    </div>
                  </div>

                  <div class="card border-0 shadow-sm card-radius mb-3">
                    <div class="card-header bg-transparent border-0">
                      <div
                        class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                      >
                        <span>Announcements</span>
                      </div>
                      <div class="float-end pt-1">
                        <a
                          href="javascript:void()"
                          class="text-decoration-none text-danger"
                          >view all<i
                            class="bi bi-chevron-right ms-1 pt-1"
                            style="font-size: 11px"
                          ></i
                        ></a>
                      </div>
                    </div>
                    <div class="card-body p-3 bac">
                      <div
                        class="card dashed-border-card"
                        style="background-color: #f0f7f8"
                      >
                        <div class="card-body p-2">
                          <div class="clear-fix"></div>
                          <div
                            class="text-primary"
                            *ngFor="let item of newsList"
                          >
                            <p
                              class="title1 mb-1 news_data"
                              data-bs-toggle="modal"
                              data-bs-target="#newsModal"
                              (click)="getNews(item.EVENT_ID)"
                            >
                              <a href="javascipt:;" style="color: #5a5a5a">{{
                                item.EVENT_NAME
                              }}</a>
                            </p>
                            <p class="dt-time mb-0">
                              {{ item.EVENT_DESCRIPTION }}
                            </p>
                          </div>
                          <!-- <div class="text-primary" *ngFor="let item of newsList">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam nunc quam, ultricies vel leo
                                consectetur, malesuada tristique tortor. Sed id sapien eu felis maximus pharetra sit amet
                                vel eros.
                              </div> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newsModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h4 class="modal-title w-100"></h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal">
          &times;
        </button>
        <!-- <h4 class="modal-title" >Upcoming Event</h4> -->
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>
      &nbsp;

      <div class="container">
        <div class="row col-12">
          <div class="col-3">
            <img [src]="news?.EVENT_IMAGE" alt="News" />
          </div>
          <div class="col-9">
            <h3>{{ news?.EVENT_NAME }}</h3>
            <P class="description">{{ news?.EVENT_DESCRIPTION }}</P>
          </div>
        </div>
      </div>
      <!-- <h5 class="description">{{news.EVENT_DESCRIPTION}}</h5> -->
      <div class="modal-footer">
        &nbsp;
        <button class="btn btn-danger" id="close" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
