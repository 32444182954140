import { FileuploadService } from "../services/fileupload.service";
import { CommonService } from "./../services/common.service";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseComponent } from "../pages/base.component";
import { ToastrService } from "ngx-toastr";
import { ChartConfiguration } from "chart.js";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-admin-dean-hod-dashboard",
  templateUrl: "./admin-dean-hod-dashboard.component.html",
  styleUrls: ["./admin-dean-hod-dashboard.component.css"],
})
export class AdminDeanHodDashboardComponent
  extends BaseComponent
  implements OnInit
{
  dashboardData: Array<any> = [];
  courseList: Array<any> = [];
  newsList: Array<any> = [];
  public barChartType: any = "bar";
  public barChartOptions: any = {
    responsive: true,
    scales: {
      xAxes: [{ gridLines: { display: false } }],
      yAxes: [{ gridLines: { display: true }, ticks: { beginAtZero: true } }],
    },
  };
  public barChartData: any[] = [];
  public barChartLabels: string[] = [];

  public pieChartOptions = { responsive: true };
  public pieChartLabels: string[] = ["KUCCPS", "PSSP"];
  public pieChartData: number[] = [];
  public pieChartColors = [
    {
      backgroundColor: ["#f87474", "#50d6ae"],
    },
  ];
  public pieChartType: any = "pie"; // Chart type
  Inclusivity: any;
  admittedCount: any;
  registeredCount: any;
  appliedCount: any;
  aluminiCount: any;
  graduationCount: any;
  kuccpsCount: any;
  responseData: Array<any> = [];
  headerText: string;
  txt: boolean;
  Admitted: boolean;
  AdminDashboard: {};
  studentfeereceivables: any;
  staffAttendancePresent: any;
  staffAttendanceAbsent: any;
  studentAttendancePresent: any;
  studentAttendanceAbsent: any;
  library: any;
  inventory: any;
  feedback: any;
  users: any;
  events: any;
  totalStaff: any;
  totalStudent: any;
  signedUp: any;
  applied: any;
  admitted: any;
  rejected: any;
  registered: any;
  applicationInPorgrs: any;
  in_review: any;
  not_registered: any;
  kuccps_yes: any;
  kuccps_no: any;
  schl_of_agri: any;
  schl_of_bus_eco: any;
  schl_of_edu: any;
  schl_of_scnc_tech: any;
  Bachelor_of_Agri_Technology_and_Food_Systems: any;
  Bachelor_of_Business_and_Entrepreneurship: any;
  Bachelor_of_Data_Science: any;
  Bachelor_of_Economics_and_Statistics: any;
  Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics: any;
  Bachelor_of_Technology_Education: any;
  PG_Diploma_in_Leadership_and_Accountability: any;
  PG_Diploma_in_Learning_Design_and_Technology: any;
  enroll: any;
  notEnroll: any;
  sentBack: any;
  Intiated: any;
  news: any;
  SchoolId: any = localStorage.getItem("SchoolId");
  DEPARTMENT_ID: any = localStorage.getItem("DepartmentId");
  @ViewChild("InputFile", { static: false }) fileInput: ElementRef;
  refreshed: boolean = false;
  reload: string = "";
  roleId: any = localStorage.getItem("RoleId");
  constructor(
    CommonService: CommonService,
    toastr: ToastrService,
    private active: ActivatedRoute,
    private FileuploadService: FileuploadService,
    private route: Router
  ) {
    super(CommonService, toastr);
    this.roleId = atob(this.roleId);
  }

  ngOnInit(): void {
    this.active.queryParams.subscribe((res: any) => {
      this.reload = res.req;
      this.refresh(this.reload);
    });

    this.getDashboardData();
    this.getCategoryWiseCourses();
    this.getBargraphCounts();
    this.loadAdminDashboard();
    this.getNewsList();

    this.myForm = this.formBuilder.group({
      EVENT_TYPE: ["", Validators.required],
      EVENT_NAME: ["", Validators.required],
      EVENT_DESCRIPTION: ["", Validators.required],
      EVENT_STATUS: [true, Validators.required],
      EVENT_IMAGE_NAME: [""],
    });
  }
  refresh(val: string) {
    if (!sessionStorage.getItem("refreshedRole")) {
      sessionStorage.setItem("refreshedRole", val);
      if (sessionStorage.getItem("refreshedRole") == val)
        window.location.reload();
    } else {
      // Optionally, clear the session storage key after reloading to allow future reloads
      sessionStorage.removeItem("refreshedRole");
    }
  }
  getDashboardData() {
    this.CommonService.postCall("Dashboard/GetAdminDeanDashboardCount", {
      TNT_CODE: this.TenantCode,
      COURSE_CATEGORY_ID:
        this.roleId == "1" || this.roleId == "2"
          ? 0
          : localStorage.getItem("SchoolId"),
      DEPARTMENT_ID:
        this.roleId == "1" || this.roleId == "2"
          ? 0
          : localStorage.getItem("DepartmentId"),
    }).subscribe((res: any) => {
      this.dashboardData = res;
      this.Inclusivity = res.find(
        (e) => e.APPLICATIONSTATUS == "DisabilityYouth"
      );
      this.admittedCount = res.find((e) => e.APPLICATIONSTATUS == "Admitted");
      this.registeredCount = res.find(
        (e) => e.APPLICATIONSTATUS == "Registered"
      );
      this.appliedCount = res.find((e) => e.APPLICATIONSTATUS == "Applied");
      this.kuccpsCount = res.find((e) => e.APPLICATIONSTATUS == "Kuccps");
      this.aluminiCount = res.find((e) => e.APPLICATIONSTATUS == "alumni");
      this.graduationCount = res.find(
        (e) => e.APPLICATIONSTATUS == "graduation"
      );
      this.pieChartData = [
        this.kuccpsCount.COUNT1,
        this.kuccpsCount.REVIEWCOUNT,
      ];
    });
  }

  getCategoryWiseCourses() {
    this.SchoolId = localStorage.getItem("SchoolId");
    this.DEPARTMENT_ID = localStorage.getItem("DepartmentId");
    [this.courseList] = [[]];
    this.activeSpinner();
    if (this.roleId == 1) {
      this.CommonService.getCall(
        `CourseSchedule/GetAllCoursesByCategoryId/${0}/${0}/${localStorage.getItem(
          "TenantCode"
        )}`
      ).subscribe(
        (res: any) => {
          this.courseList = res?.dtCourseScehdule;
          this.deactivateSpinner();
        },
        (e) => {
          this.deactivateSpinner();
        }
      );
    }
    // (this.SchoolId !='0' && this.DEPARTMENT_ID=='0')
    else if (this.roleId != 1)
      this.CommonService.postCall("Courses/GetCoursesByRole", {
        ROLE_IDS: localStorage.getItem("RoleId"),
        TNT_CODE: +this.TenantCode,
        COURSE_TRAINER_TRAINER_ID: +this.userId,
        COURSE_CATEGORY_ID:
          this.roleId == "1" || this.roleId == "2"
            ? 0
            : localStorage.getItem("SchoolId"),
        DEPARTMENT_ID:
          this.roleId == "1" || this.roleId == "2"
            ? 0
            : localStorage.getItem("DepartmentId"),
        Type: localStorage.getItem("TYPE"),
      }).subscribe((res: any) => {
        this.courseList = res;
        this.deactivateSpinner();
      });
    this.deactivateSpinner();
  }
  getBargraphCounts() {
    this.CommonService.postCall("Dashboard/GetAdminDeanDashboardGraphList", {
      TNT_CODE: this.TenantCode,
      Decrypt_Role: localStorage.getItem("RoleId"),
      USER_ID: this.userId,
    }).subscribe((res: any) => {
      this.barChartData = [
        {
          data: res?.data?.Admittedcount, // Use the array directly
          label: "Admitted",
          backgroundColor: "#FF7043",
        },
        {
          data: res?.data?.Registeredcount, // Use the array directly
          label: "Registered",
          backgroundColor: "#00796B",
        },
        {
          data: res?.data?.PendingApplicationcount, // Use the array directly
          label: "Pending Application",
          backgroundColor: "#BDBDBD",
        },
      ];
      this.barChartLabels = res?.schoolnames;
    });
  }
  showAllPrograms() {
    this.route.navigate(["/HOME/courseSetup/courses/22"]);
  }

  registrationDetails(status: number, txt: string, cId: any) {
    this.activeSpinner();
    this.headerText = "";
    this.headerText = txt;
    let headers = [
      "Enroled",
      "Initiated",
      "Not Enroled",
      "In Review",
      "Admitted",
      "Accepted",
      "Alumni",
      "Graduation",
    ];
    this.txt = headers.includes(txt);
    this.Admitted = txt.includes("Admitted");
    this.responseData = [];
    let payload = {
      TNT_CODE: localStorage.getItem("TenantCode"),
      STATUS: status,
      CID: cId,
      SchoolId: localStorage.getItem("SchoolId"),
      DepartmentId: localStorage.getItem("DepartmentId"),
    };
    this.CommonService.postCall(
      "Registration/Loadregistrationbreakupdetails",
      payload
    ).subscribe(
      (res: any) => {
        this.responseData = res;
        this.deactivateSpinner();
      },
      (e: any) => {
        this.deactivateSpinner();
      }
    );
  }

  loadAdminDashboard() {
    this.AdminDashboard = {};
    this.CommonService.getCall(
      `Dashboard/LoadAdminOrSuperadminDashboard/${localStorage.getItem(
        "RoleId"
      )}/${localStorage.getItem("TenantCode")}`
    ).subscribe((res: any) => {
      this.AdminDashboard = res;
      this.studentfeereceivables = res["studentfeereceivables"];
      this.staffAttendancePresent = res["staffattendance"][0];
      this.staffAttendanceAbsent = res["staffattendance"][2];
      this.studentAttendancePresent = res["studentattendance"][0];
      this.studentAttendanceAbsent = res["studentattendance"][1];
      this.library = res["library"];
      this.inventory = res["inventory"];
      this.feedback = res["feedback"];
      this.users = res["users1"];
      this.events = res["Event"];
      this.courses = res["Courese"];
      this.totalStaff = res["Totalstaff"];
      this.totalStudent = res["TotalStudents"];
      this.signedUp = res["SignedUp"];
      this.applied = res["Applied"];
      this.admitted = res["Admitted"];
      this.rejected = res["Rejected"];
      this.registered = res["Registered"];
      this.applicationInPorgrs = res["Application_in_Progress"];
      this.in_review = res["In_Review"];
      this.not_registered = res["Not_Registered"];
      this.kuccps_yes = res["Kuccps_Yes"];
      this.kuccps_no = res["Kuccps_No"];
      this.schl_of_agri = res["School_of_Agriculture"];
      this.schl_of_bus_eco = res["School_of_Business_and_Economics"];
      this.schl_of_edu = res["School_of_Education"];
      this.schl_of_scnc_tech = res["School_of_Science_and_Technology"];

      this.Bachelor_of_Agri_Technology_and_Food_Systems =
        res["Bachelor_of_Agri_Technology_and_Food_Systems"];
      this.Bachelor_of_Business_and_Entrepreneurship =
        res["Bachelor_of_Business_and_Entrepreneurship"];
      this.Bachelor_of_Data_Science = res["Bachelor_of_Data_Science"];
      this.Bachelor_of_Economics_and_Statistics =
        res["Bachelor_of_Economics_and_Statistics"];
      this.Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics =
        res["Bachelor_of_Science_in_Cybersecurity_and_Digital_Forensics"];
      this.Bachelor_of_Technology_Education =
        res["Bachelor_of_Technology_Education"];
      this.PG_Diploma_in_Leadership_and_Accountability =
        res["PG_Diploma_in_Leadership_and_Accountability"];
      this.PG_Diploma_in_Learning_Design_and_Technology =
        res["PG_Diploma_in_Learning_Design_and_Technology"];
      this.enroll = res["Enroll"];
      this.notEnroll = res["Not_Enrolled"];
      this.sentBack = res["Sentback"];
      this.Intiated = res["Initiate"];
    });
  }
  enrouteLibrary() {
    window.open("https://admin.myloft.xyz/user/login");
  }

  getNewsList() {
    this.newsList = [];
    let payload = {
      TNT_CODE: localStorage.getItem("TenantCode"),
      ROLE_NAME: localStorage.getItem("RoleName"),
    };
    this.CommonService.postCall("News/GetNewsList", payload).subscribe(
      (response: any) => {
        this.newsList = response;
      }
    );
  }
  getNews(EVENT_ID) {
    this.news = {};
    let payload = {
      EVENT_ID: EVENT_ID,
    };
    this.CommonService.postCall("News/Get", payload).subscribe(
      (response: any) => {
        this.news = response;
      }
    );
  }
  close() {
    this.myForm.reset();
    this.isEdit = false;
    this.editData = {};
    this.fileInput.nativeElement.value = null;
  }

  onSubmit(form: FormGroup) {
    let payaLoad = form.value;
    payaLoad.TENANT_CODE = this.TenantCode;
    payaLoad.EVENT_CREATEDBY =
      this.editData.EVENT_CREATEDBY || localStorage.getItem("UserId");
    payaLoad.EVENT_IMAGE_NAME = this.fileName;
    payaLoad.CREATEDDATE = this.editData.CREATEDDATE || moment(new Date());
    if (this.isEdit) {
      payaLoad.EVENT_ID = this.editData.EVENT_ID;
      this.CommonService.postCall("CreateNewsUpdate", payaLoad).subscribe(
        (res: any) => {
          this.toastr.success("News Updated Successfully");
          document.getElementById("md_close")?.click();
          this.fileInput.nativeElement.value = null;
        },
        (err) => {
          this.toastr.error(err.error ? err.error : "News  not Updated");
        }
      );
    } else {
      this.CommonService.postCall("CreateNewssave", payaLoad).subscribe(
        (res: any) => {
          this.toastr.success("News created Successfully");
          document.getElementById("md_close")?.click();
          this.fileInput.nativeElement.value = null;
        },
        (err) => {
          this.toastr.error(err.error ? err.error : "News  not created ");
        }
      );
    }
  }

  changeFile(event) {
    if (event.target.files.length > 0) {
      let file: File = event.target.files[0];
      let name = file.name;
      let filetype = name.split(".").pop();

      // if (check) {
      this.file = file;
      this.upload();
      // }
      // else {
      //   // alert(' Please upload pdf and doc file formats only.')
      //   this.toastr.warning('Please upload Xls,xlsx file formats only.')
      //   event.target.value = ''
      // }
    }
  }
  upload() {
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("ClientDocs", "ClientDocs");

    this.activeSpinner();
    this.FileuploadService.upload(formData, "UploadNews").subscribe(
      (res: any) => {
        try {
          this.fileName = res.path;
          if (res.ValidationMessage) {
            this.deactivateSpinner();
            this.toastr.warning(res.ValidationMessage);
          }
          if (this.fileName) {
            this.deactivateSpinner();
            this.myForm.controls["EVENT_IMAGE_NAME"].setValue(this.fileName);
          }
        } catch (e) {
          console.log(e);
        }
      },
      (err) => {
        this.deactivateSpinner();
      }
    );
  }
}
