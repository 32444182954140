import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../pages/base.component";
import { CommonService } from "../services/common.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-new-student-dashboard",
  templateUrl: "./new-student-dashboard.component.html",
  styleUrls: ["./new-student-dashboard.component.css"],
})
export class NewStudentDashboardComponent
  extends BaseComponent
  implements OnInit
{
  dashboardResponse: any;
  newsList: Array<any> = [];
  news: any;
  showAll: boolean = false;
  currentDate: Date = new Date();
  isApplicant: any = localStorage.getItem("IsApplicant");
  constructor(CommonService: CommonService, toastr: ToastrService) {
    super(CommonService, toastr);
    this.roleId = atob(this.roleId);
    console.log(this.roleId);
  }

  ngOnInit(): void {
    this.getDashboardDetails();
    this.getNewsList();
  }
  getDashboardDetails() {
    this.activeSpinner();
    this.CommonService.postCall("Dashboard/GetStudentDashboardDetails", {
      USER_ID: this.userId,
      TNT_CODE: this.TenantCode,
    }).subscribe((res: any) => {
      this.dashboardResponse = res;
      this.deactivateSpinner();
    });
  }
  getNewsList() {
    this.newsList = [];
    let payload = {
      TNT_CODE: localStorage.getItem("TenantCode"),
      ROLE_NAME: localStorage.getItem("RoleName"),
    };
    this.CommonService.postCall("News/GetNewsList", payload).subscribe(
      (response: any) => {
        this.newsList = response;
      }
    );
  }
  getNews(EVENT_ID) {
    this.news = {};
    let payload = {
      EVENT_ID: EVENT_ID,
    };
    this.CommonService.postCall("News/Get", payload).subscribe(
      (response: any) => {
        this.news = response;
      }
    );
  }
  toggleViewAll() {
    this.showAll = !this.showAll;
  }
}
