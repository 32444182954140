import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "./../../environments/environment";
// const url = environment.serviceUrl

@Injectable({
  providedIn: "root",
})
export class LoginService {
  url = environment.serviceUrl;
  constructor(private http: HttpClient) {
    if (location.hostname != "localhost") this.url = `${location.origin}/api/`;
  }

  login(data) {
    return this.http.post(this.url + "Account/Login", data);
  }

  vendorlogin(data) {
    return this.http.post(this.url + "Account/VendorLogIn", data);
  }
  loginNew(data) {
    let url =
      location.href == "https://oukdevapplicant.samvaadpro.com/login"
        ? "https://oukdevapplicant.samvaadpro.com/api/"
        : location.href == "https://oukqaapplicant.samvaadpro.com/login"
        ? "https://oukqaapplicant.samvaadpro.com/api/"
        : location.href == "https://oukadmissionsdev.konza.go.ke/login"
        ? "https://oukadmissionsdev.konza.go.ke/api/"
        : location.href == "https://oukdevapplicanttest.samvaadpro.com/login"
        ? "https://oukdevapplicanttest.samvaadpro.com/login"
        : location.href == "http://localhost:4200/login"
        ? "https://oukdevapplicant.samvaadpro.com/api/"
        : "https://admissions.ouk.ac.ke/api/";
    return this.http.post(url + "Account/InitialLogin", data);
  }
  aluminiLogin(data) {
    let url =
      location.href == "https://oukalumnidev.samvaadpro.com/login"
        ? "https://oukalumnidev.samvaadpro.com/api/"
        : location.href == "https://oukalumniqa.samvaadpro.com/login"
        ? "https://oukalumniqa.samvaadpro.com/api/"
        : location.href == "http://localhost:4200/login"
        ? "https://oukalumnidev.samvaadpro.com/api/"
        : "/";
    return this.http.post(url + "Account/LoginAlumni", data);
  }
  forgotPassword(payload: string) {
    return this.http.get(`${this.url}${payload}`);
  }
}
