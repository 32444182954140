<section
  [ngClass]="{ 'pt-1': !isShowAll }"
  [ngStyle]="{ 'padding-top': isShowAll ? '7rem' : null }"
>
  <div class="container post pt-1">
    <div class="row DCB-card" style="padding-top: 1.25rem !important">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" *ngIf="shortCourses.length > 0">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button fw-bold"
              type="button"
              data-bs-toggle="collapse"
              style="font-size: large"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {{ shortCourses[0]?.COURSE_TYPE_NAME }}
              <!-- Professional Development and Short Courses -->
            </button>
          </h2>
          <div
            id="collapseOne"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div
                  class="col-xl-4 col-md-6 mb-5 pt-2"
                  *ngFor="let course of shortCourses"
                >
                  <div class="card shadow-sm h-100">
                    <div style="position: relative">
                      <img
                        [src]="
                          course.COURSE_IMAGE
                            ? url + course.COURSE_IMAGE
                            : '../../../assets/img/subject-img.jpg'
                        "
                        class="card-img-top"
                        alt="..."
                        width="354"
                        height="185"
                      />
                      <span
                        class="badge rounded-pill bg-light text-dark user-pill px-3 py-2"
                      ></span>
                    </div>
                    <div class="card-body">
                      <h5
                        class="card-title mt-2 text-start"
                        style="
                          line-height: 19px !important;
                          height: auto !important;
                        "
                      >
                        {{ course.COURSE_NAME }}
                      </h5>
                      <p class="card-text text-start mb-1 fw-bold">
                        {{ course?.COURSE_DESCRIPTION?.substr(0, 150) }}
                        <a
                          *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                          (click)="gotoCourseDetail(course, 1)"
                          href="javascript:return(0);"
                          >View More</a
                        >
                      </p>

                      <div class="text-center">
                        <div>
                          <span
                            ><button
                              class="btn btn-sm btn-seeall-1"
                              style="
                                background-color: #ff7f50 !important;
                                color: white;
                                width: 100%;
                              "
                              (click)="gotoCourseDetail(course, 1)"
                            >
                              Apply
                            </button></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button
              class="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              style="font-size: large"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {{ undergraduateCourses[0]?.COURSE_TYPE_NAME }}
              <!-- Undergraduate Programmes -->
            </button>
          </h2>
          <div
            id="collapseTwo"
            class="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div
                  class="col-xl-4 col-md-6 mb-5 pt-2"
                  *ngFor="let course of undergraduateCourses"
                >
                  <div class="card shadow-sm h-100">
                    <div style="position: relative">
                      <img
                        [src]="
                          course.COURSE_IMAGE
                            ? url + course.COURSE_IMAGE
                            : '../../../assets/img/subject-img.jpg'
                        "
                        class="card-img-top"
                        alt="..."
                        width="354"
                        height="185"
                      />
                      <span
                        class="badge rounded-pill bg-light text-dark user-pill px-3 py-2"
                      ></span>
                    </div>
                    <div class="card-body">
                      <h5
                        class="card-title mt-2 text-start"
                        style="
                          line-height: 19px !important;
                          height: auto !important;
                        "
                      >
                        {{ course.COURSE_NAME }}
                      </h5>
                      <p class="card-text text-start mb-1 fw-bold">
                        {{ course?.COURSE_DESCRIPTION?.substr(0, 150) }}
                        <a
                          *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                          (click)="gotoCourseDetail(course, 2)"
                          href="javascript:return(0);"
                          >View More</a
                        >
                        <!-- <span
                          class="badge rounded-pill text-bg-primary badgecolor"
                        >
                          School of Science
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-box-arrow-up-right"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"
                            />
                            <path
                              fill-rule="evenodd"
                              d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"
                            />
                          </svg>
                        </span> -->
                      </p>

                      <div class="text-center">
                        <div>
                          <span
                            ><button
                              class="btn btn-sm btn-seeall-1"
                              style="
                                background-color: #ff7f50 !important;
                                color: white;
                                width: 100%;
                              "
                              (click)="gotoCourseDetail(course, 2)"
                            >
                              Apply
                            </button></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button
              class="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              style="font-size: large"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              {{ postgraduateCourses[0]?.COURSE_TYPE_NAME }}
              <!-- Postgraduate Programmes -->
            </button>
          </h2>
          <div
            id="collapseThree"
            class="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div class="accordion-body">
              <div class="row">
                <div
                  class="col-xl-4 col-md-6 mb-5 pt-2"
                  *ngFor="let course of postgraduateCourses"
                >
                  <div class="card shadow-sm h-100">
                    <div style="position: relative">
                      <img
                        [src]="
                          course.COURSE_IMAGE
                            ? url + course.COURSE_IMAGE
                            : '../../../assets/img/subject-img.jpg'
                        "
                        class="card-img-top"
                        alt="..."
                        width="354"
                        height="185"
                      />
                      <span
                        class="badge rounded-pill bg-light text-dark user-pill px-3 py-2"
                      ></span>
                    </div>
                    <div class="card-body">
                      <h5 class="card-title mt-2">{{ course.COURSE_NAME }}</h5>
                      <p class="card-text">
                        {{ course?.COURSE_DESCRIPTION?.substr(0, 150) }}
                        <a
                          *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                          (click)="gotoCourseDetail(course, 3)"
                          href="javascript:return(0);"
                          >View More</a
                        >
                      </p>
                      <div class="text-center">
                        <div>
                          <span
                            ><button
                              class="btn btn-sm btn-seeall-1"
                              (click)="gotoCourseDetail(course, 3)"
                            >
                              View Programme
                            </button></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="text-center mb-3">
            <button type="button" class="btn btn-blue btn-sm">Small button</button>
        </div> -->
  </div>
</section>
